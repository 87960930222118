import React, { useState } from "react";
import TimeInput from "./TimeInput";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import { useLanguage } from "../../../LanguageProvider";
import { IoMdPlay } from "react-icons/io";
import "./TimerList.css";

export default function TimerList({
  timeLog,
  handleDelete,
  handleUpdateLog,
  onPlay,
}) {
  const { getTranslation } = useLanguage();
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [tempTitle, setTempTitle] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFieldClick = (id, field, title = "") => {
    setEditingIndex(id);
    setEditingField(field);
    if (field === "title") setTempTitle(title);
  };

  const handleSaveTitle = (id, newTitle) => {
    const index = timeLog.findIndex((log) => log.id === id);
    handleUpdateLog(index, "title", newTitle);
    setEditingIndex(null);
    setEditingField(null);
  };

  const handleSaveStart = (id, newHours, newMinutes) => {
    const index = timeLog.findIndex((log) => log.id === id);
    const log = timeLog[index];
    const newStartDate = new Date(log.start);
    newStartDate.setHours(newHours);
    newStartDate.setMinutes(newMinutes);

    const newDuration = log.end
      ? Math.floor((log.end - newStartDate) / 1000)
      : 0;
    handleUpdateLog(index, "start", newStartDate);
    handleUpdateLog(index, "duration", newDuration);

    setEditingIndex(null);
    setEditingField(null);
  };

  const handleSaveEnd = (id, newHours, newMinutes) => {
    const index = timeLog.findIndex((log) => log.id === id);
    const log = timeLog[index];
    const newEndDate = new Date(log.end);
    newEndDate.setHours(newHours);
    newEndDate.setMinutes(newMinutes);

    const newDuration = log.start
      ? Math.floor((newEndDate - log.start) / 1000)
      : 0;
    handleUpdateLog(index, "end", newEndDate);
    handleUpdateLog(index, "duration", newDuration);

    setEditingIndex(null);
    setEditingField(null);
  };

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteId(id);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    handleDelete(deleteId);
    setIsModalOpen(false);
    setDeleteId(null);
  };

  const categorizeByDate = (data) => {
    const today = new Date();
    const categories = {};

    data.forEach((item) => {
      const itemDate = new Date(item.start);
      const isToday =
        itemDate.getDate() === today.getDate() &&
        itemDate.getMonth() === today.getMonth() &&
        itemDate.getFullYear() === today.getFullYear();

      const dateKey = isToday
        ? "Today"
        : itemDate.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

      if (!categories[dateKey]) {
        categories[dateKey] = [];
      }
      categories[dateKey].push(item);
    });

    return Object.entries(categories).sort(([dateA], [dateB]) => {
      if (dateA === "Today") return -1;
      if (dateB === "Today") return 1;
      return new Date(dateB) - new Date(dateA);
    });
  };

  const handlePlay = (log) => {
    const groupId = log.groupId || Math.random().toString(36).substring(2, 11);
    onPlay({ ...log, groupId }); // id, title ve mevcut groupId gönder
  };

  const categorizedData = categorizeByDate(timeLog);

  return (
    <>
      {categorizedData.length > 0 && (
        <ul className="time-log">
          {categorizedData.map(([date, logs]) => (
            <React.Fragment key={date}>
              <li className="date-header">{date}</li>
              {logs.map((log) => (
                <li key={log.id} className="time-log-item">
                  {editingIndex === log.id && editingField === "title" ? (
                    <input
                      type="text"
                      value={tempTitle}
                      onChange={(e) => setTempTitle(e.target.value)}
                      onBlur={() => handleSaveTitle(log.id, tempTitle)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSaveTitle(log.id, tempTitle);
                        }
                      }}
                      autoFocus
                      className="editable-title-input"
                    />
                  ) : (
                    <span
                      className="title"
                      onClick={() =>
                        handleFieldClick(log.id, "title", log.title)
                      }
                    >
                      {log.title}
                    </span>
                  )}

                  <div className="right-items">
                    <IoMdPlay
                      size={18}
                      style={{
                        cursor: "pointer",
                        marginRight: "8px",
                        color: "green",
                      }}
                      onClick={() => handlePlay(log)}
                    />

                    <span className="times">
                      {editingIndex === log.id && editingField === "start" ? (
                        <>
                          <TimeInput
                            value={new Date(log.start).getHours()}
                            onSave={(newHours) =>
                              handleSaveStart(
                                log.id,
                                newHours,
                                new Date(log.start).getMinutes()
                              )
                            }
                            max={23}
                            isHour={true}
                          />
                          :
                          <TimeInput
                            value={new Date(log.start).getMinutes()}
                            onSave={(newMinutes) =>
                              handleSaveStart(
                                log.id,
                                new Date(log.start).getHours(),
                                newMinutes
                              )
                            }
                            max={59}
                          />
                        </>
                      ) : (
                        <span onClick={() => handleFieldClick(log.id, "start")}>
                          {new Date(log.start).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      )}
                      <p className="line">-</p>
                      {editingIndex === log.id && editingField === "end" ? (
                        <>
                          <TimeInput
                            value={new Date(log.end).getHours()}
                            onSave={(newHours) =>
                              handleSaveEnd(
                                log.id,
                                newHours,
                                new Date(log.end).getMinutes()
                              )
                            }
                            max={23}
                            isHour={true}
                          />
                          :
                          <TimeInput
                            value={new Date(log.end).getMinutes()}
                            onSave={(newMinutes) =>
                              handleSaveEnd(
                                log.id,
                                new Date(log.end).getHours(),
                                newMinutes
                              )
                            }
                            max={59}
                          />
                        </>
                      ) : (
                        <span onClick={() => handleFieldClick(log.id, "end")}>
                          {new Date(log.end).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      )}
                    </span>

                    <span className="duration">
                      {formatDuration(log.duration)}
                    </span>

                    <button
                      className="delete-button"
                      onClick={() => handleOpenDeleteModal(log.id)}
                    >
                      {getTranslation("delete")}
                    </button>
                  </div>
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
      )}

      <ConfirmDeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmDelete}
      />
    </>
  );
}
