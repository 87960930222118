// Toaster.js
import React, { useState, useEffect } from 'react';
import './Toaster.css';
import { FaTimes } from 'react-icons/fa';  // X ikonu

const Toaster = ({ message, type, duration = 3000, onClose, position = { bottom: '20px', right: '20px' } }) => {
  const [visible, setVisible] = useState(true);
  const [progress, setProgress] = useState(100); // Progress bar genişliği

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => prev - 100 / (duration / 100)); // Her 100ms'de bir azalma
    }, 100);

    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) onClose();
    }, duration);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [duration, onClose]);

  const handleClose = () => {
    setVisible(false);
    if (onClose) onClose();
  };

  if (!visible) return null;

  return (
    <div className={`toaster ${type}`} style={position}>
      <span style={{alignItems:'center',display:'flex',fontSize:'14px',marginLeft:'15px',justifyContent:'space-between',marginRight:'5px',marginBottom:'5px'}}>{message} <FaTimes className="toaster-close" onClick={handleClose} /></span>
      <div className="toaster-progress" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default Toaster;
