import React from "react";
import "./MessageBox.css";
import { useLanguage } from "../../LanguageProvider";

const MessageBox = ({ show, onClose, onConfirm, title, message }) => {
  const { getTranslation } = useLanguage();

  if (!show) {
    return null;
  }

  return (
    <div className="messageBox-overlay">
      <div className="messageBox">
        <h2 className="messageBox-title">{title}</h2>
        <p className="messageBox-message">{message}</p>
        <div className="messageBox-buttons">
          <button className="messageBox-button cancel" onClick={onClose}>
            {getTranslation("no")}
          </button>
          <button className="messageBox-button confirm" onClick={onConfirm}>
            {getTranslation("yes")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
