import React from 'react'
import JsonEditor from '../../../components/JsonEditor'
import { useLanguage } from '../../../LanguageProvider';

export default function Body({ code, setCode, result }) {
    const { getTranslation } = useLanguage();

    return (
        <div className='card'>
            <JsonEditor code={code} setCode={setCode} />

            <p style={{marginTop:'30px',marginBottom:'30px'}}>{getTranslation('response')}</p>

            {result ?
                <JsonEditor code={result} setCode={setCode} readOnly={true} />
                :
                <div style={{alignItems:'center',justifyContent:'center',display:'flex'}}>
                    <p style={{fontSize:'12px'}}>{getTranslation('seeResponse')}</p>
                </div>
            }

        </div>
    )
}
