import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const navigate = useNavigate();
  const sessionToken = sessionStorage.getItem('token');
  const localToken = localStorage.getItem('token');
  const isAuthenticated = !!sessionToken || !!localToken;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return element; 
};

export default ProtectedRoute;