import React, { useContext } from "react";
import "./MainPage.css";
import { GeneralContext } from "../../GeneralProvider";
import ApiPage from "../ApiPage/ApiPage";
import DocumentationPage from "../DocumentationPage/DocumentationPage";
import TestAutomationPage from "../TestAutomationPage/TestAutomationPage";
import { useLanguage } from "../../LanguageProvider";
import ProfilePage from "../ProfilePage/ProfilePage";
import TaskManager from "../TaskManager/TaskManager";
import Kronos from "../Kronos/Kronos";
import ProjectsPage from "../ProjectsPage/ProjectsPage";

export default function MainPage() {
  const {
    currentPage,
    selectedApi,
    collections,
    _setIsAddCollectionModalOpen,
    _setIsAddProjectModalOpen,
    projects
  } = useContext(GeneralContext);
  const { getTranslation } = useLanguage();

  const renderPage = () => {
    switch (currentPage) {
      case "apis":
        return <ApiPage />;
      case "documentation":
        return <DocumentationPage />;
      case "testautomation":
        return <TestAutomationPage />;
      case "profile":
        return <ProfilePage />;
      case "task-management":
        return <TaskManager />;
      case "kronos":
        return <Kronos />;
      case "projects":
        return <ProjectsPage />;
      default:
        return null;
    }
  };

  if (
    ((Object.keys(selectedApi).length > 0 ||
      currentPage === "apis" ||
      currentPage === "task-management" ||
      currentPage === "kronos" ||
      currentPage === "projects") && projects.length > 0) || currentPage === "profile"
  ) {
    return <>{renderPage()}</>;
  } else {
    return (
      <div className="main-page">
        <div
          style={{
            width: "100%",
            height: "65%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {projects.length === 0 ?
            <button onClick={() => _setIsAddProjectModalOpen(true)}>
              {getTranslation("createNewProject")}
            </button> :
            <>
              {collections.length === 0 ? (
                <button onClick={() => _setIsAddCollectionModalOpen(true)}>
                  {getTranslation("createNewCollection")}
                </button>
              ) : (
                <p>{getTranslation("selectApi")}</p>
              )}
            </>
          }


        </div>
      </div>
    );
  }
}
