import React, { useState, useRef, useEffect } from "react";
import AddManualModal from "./AddManualModal";
import { useLanguage } from "../../../LanguageProvider";
import "./Timer.css";
import TimeInput from "./TimeInput";

export default function Timer({ onAddLog, timeLog, setTimeLog, currentLog }) {
  const { getTranslation } = useLanguage();
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [title, setTitle] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [isManualModalOpen, setIsManualModalOpen] = useState(false);
  const [showStartTimeInput, setShowStartTimeInput] = useState(false);
  const startTimeRef = useRef(null);

  useEffect(() => {
    if (currentLog) {
      if (isRunning) {
        stopTimer(); // Mevcut çalışan timer varsa durdurup kaydediyoruz
      }
      startNewTimer(currentLog); // Yeni log ile timer başlatıyoruz
    }
  }, [currentLog]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        startTimeRef.current &&
        !startTimeRef.current.contains(event.target)
      ) {
        setShowStartTimeInput(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [startTimeRef]);

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const startNewTimer = (log) => {
    setTitle(log.title);
    setGroupId(log.groupId || Math.random().toString(36).substring(2, 11));
    setCurrentId(log.id);
    startTimer();
  };

  const startTimer = () => {
    const newStartTime = new Date();
    setStartTime(newStartTime);
    setIntervalId(setInterval(() => setTime((prevTime) => prevTime + 1), 1000));
    setIsRunning(true);
  };

  const stopTimer = () => {
    clearInterval(intervalId);
    const endTime = new Date();
    const newLog = {
      title,
      start: startTime,
      duration: time,
      end: endTime,
      id: Math.random().toString(36).substring(2, 11),
      groupId: groupId || Math.random().toString(36).substring(2, 11),
    };

    const updatedLogs = timeLog.map((log) => {
      if (log.id === currentId || log.groupId === groupId) {
        return { ...log, groupId: newLog.groupId };
      }
      return log;
    });

    setTimeLog([...updatedLogs, newLog]);
    resetTimer();
  };

  const resetTimer = () => {
    setTitle("");
    setGroupId(null);
    setCurrentId(null);
    setIsRunning(false);
    setTime(0);
  };

  const handleStartStop = () => {
    if (isRunning) {
      stopTimer();
    } else {
      startTimer();
    }
  };

  const handleStartTimeChange = (hours, minutes) => {
    if (startTime) {
      const updatedStartTime = new Date(startTime);
      updatedStartTime.setHours(hours);
      updatedStartTime.setMinutes(minutes);
      setStartTime(updatedStartTime);

      const newDuration = Math.floor((new Date() - updatedStartTime) / 1000);
      setTime(newDuration);
    }
  };

  const handleSaveStartTime = () => {
    setShowStartTimeInput(false);
    if (startTime) {
      const newDuration = Math.floor((new Date() - startTime) / 1000);
      setTime(newDuration);
    }
  };

  const handleAddManualEntry = ({ title, startTime, endTime }) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startDate = new Date();
    startDate.setHours(startHour);
    startDate.setMinutes(startMinute);

    const endDate = new Date();
    endDate.setHours(endHour);
    endDate.setMinutes(endMinute);

    const durationInSeconds = Math.floor((endDate - startDate) / 1000);

    const newLog = {
      title: title || "Unnamed Task",
      start: startDate,
      duration: durationInSeconds,
      end: endDate,
      id: Math.random().toString(36).substring(2, 11),
    };

    setTimeLog((prevLogs) => [...prevLogs, newLog]);
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="timer-container">
        <input
          type="text"
          className="timer-input"
          placeholder={getTranslation("whatAreYouWorkingOn")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <span
          className={`timer-time ${isRunning ? "pointer" : ""}`}
          onClick={() =>
            isRunning ? setShowStartTimeInput(!showStartTimeInput) : null
          }
        >
          {formatTime(time)}
        </span>

        <button
          className={`start-button ${isRunning ? "stop-button" : ""}`}
          onClick={handleStartStop}
        >
          {isRunning ? getTranslation("stop") : getTranslation("start")}
        </button>

        <button
          className="add-manual-button"
          onClick={() => setIsManualModalOpen(true)}
        >
          {getTranslation("addManual")}
        </button>
      </div>

      {showStartTimeInput && (
        <div ref={startTimeRef} className="start-time-input-popup">
          <span
            className="close-x-button"
            onClick={() => setShowStartTimeInput(false)}
          >
            ×
          </span>
          <label>{getTranslation("startTime")}:</label>
          <div>
            <TimeInput
              value={startTime ? startTime.getHours() : 0}
              onSave={(newHours) =>
                handleStartTimeChange(
                  newHours,
                  startTime ? startTime.getMinutes() : 0
                )
              }
              max={23}
              isHour={true}
            />
            <span style={{ paddingInline: "5px" }}>:</span>
            <TimeInput
              value={startTime ? startTime.getMinutes() : 0}
              onSave={(newMinutes) =>
                handleStartTimeChange(
                  startTime ? startTime.getHours() : 0,
                  newMinutes
                )
              }
              max={59}
            />
          </div>

          <button onClick={handleSaveStartTime} className="save-button">
            {getTranslation("save")}
          </button>
        </div>
      )}

      <AddManualModal
        isOpen={isManualModalOpen}
        onClose={() => setIsManualModalOpen(false)}
        onAdd={handleAddManualEntry}
      />
    </div>
  );
}
