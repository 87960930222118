import React from 'react';
import Modal from '../../../components/Modal/Modal';
import { useLanguage } from '../../../LanguageProvider';

const TodayTaskModal = ({ isOpen, onClose, tasks, onCheckboxChange, isCheckboxChecked, taskOnClick }) => {
    const { getTranslation } = useLanguage();

    const handleCheckboxChange = (e) => {
        onCheckboxChange(e.target.checked);
    };

    const formatDate = (date) => {
        if (!date) return '';
        const daysOfWeek = [
            getTranslation('sunday'),
            getTranslation('monday'),
            getTranslation('tuesday'),
            getTranslation('wednesday'),
            getTranslation('thursday'),
            getTranslation('friday'),
            getTranslation('saturday')
        ];

        const months = [
            getTranslation('january'),
            getTranslation('february'),
            getTranslation('march'),
            getTranslation('april'),
            getTranslation('may'),
            getTranslation('june'),
            getTranslation('july'),
            getTranslation('august'),
            getTranslation('september'),
            getTranslation('october'),
            getTranslation('november'),
            getTranslation('december')
        ];

        const dayName = daysOfWeek[date.getDay()];
        const dayNumber = date.getDate();
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();

        return `${dayName}, ${dayNumber} ${monthName} ${year}`;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} position={'middle'}>
            <h2>{getTranslation('todayTasks')}</h2>
            {tasks.map((task, index) => (
                <div
                    onClick={() => taskOnClick(task)}
                    style={{
                        cursor: 'pointer',
                        marginTop: '10px',
                        backgroundColor: 'var(--task-card-bg-color)',
                        borderRadius: '50px',
                        minHeight: '60px',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '0.85em',
                        color: '#fff',
                        marginBottom: '10px',
                        flexDirection: 'column',
                        paddingInline: '15px',
                        overflow: 'hidden',
                    }} key={index}>
                    <span>{task?.taskTitle}</span>
                    <span style={{ width: '100%', display: 'flex', fontSize: '10px' }}>
                        {formatDate(new Date(task?.taskStart))} - {task?.taskStart.split(' ')[1].slice(0, 5)} - {formatDate(new Date(task?.taskEnd))} - {task?.taskEnd.split(' ')[1].slice(0, 5)}
                    </span>
                </div>
            ))}

            <label style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                userSelect: 'none',
                gap: '5px',
                fontSize: '14px',
                flexGrow: 1,
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                marginTop: '25px',
                justifyContent: 'flex-end'
            }}>
                <input
                    checked={isCheckboxChecked}
                    type="checkbox"
                    onChange={handleCheckboxChange}
                />
                {getTranslation('doNotShowAgainToday')}
            </label>
        </Modal >
    );
};

export default TodayTaskModal;
