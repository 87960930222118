import React, { useContext, useEffect, useRef, useState } from 'react';
import './Calendar.css';
import { useLanguage } from '../../../LanguageProvider';
import TaskDetailModal from './TaskDetailModal';
import { GeneralContext } from '../../../GeneralProvider';
import { MdModeEdit } from 'react-icons/md';
import { IoAddSharp } from "react-icons/io5";
import MessageBox from '../../../components/MessageBox/MessageBox';
import AddTaskModal from './AddTaskModal';
import TodayTaskModal from './TodayTaskModal';

const Calendar = () => {
  const { getTranslation } = useLanguage();
  const { selectedBoard, handleUpdateBoardTitle, handleDeleteBoard } = useContext(GeneralContext);
  const calendarRef = useRef(null);
  const [tasks, setTasks] = useState([]);
  const [boardTitle, setBoardTitle] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showBoardDeleteModal, setShowBoardDeleteModal] = useState(false);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [todaysTasks, setTodaysTasks] = useState([]);
  const today = new Date().toISOString().slice(0, 10);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [showModal, setShowModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [showTodayTaskModal, setShowTodayTaskModal] = useState(false);
  const [dontShowToday, setDontShowToday] = useState(false);
  const [hasInitializedTodayTaskModal, setHasInitializedTodayTaskModal] = useState(false);

  useEffect(() => {
    const sortedTasks = selectedBoard.tasks.sort((a, b) => {
      return a.taskId.localeCompare(b.taskId);
    });
    setTasks(sortedTasks)
    setBoardTitle(selectedBoard.boardTitle)
  }, [selectedBoard]);

  const monthNames = [getTranslation('january'), getTranslation('february'), getTranslation('march'), getTranslation('april'), getTranslation('may'), getTranslation('june'), getTranslation('july'), getTranslation('august'), getTranslation('september'), getTranslation('october'), getTranslation('november'), getTranslation('december')];
  const dayNames = [getTranslation('sunday'), getTranslation('monday'), getTranslation('tuesday'), getTranslation('wednesday'), getTranslation('thursday'), getTranslation('friday'), getTranslation('saturday')];

  useEffect(() => {
    const storedCheckboxState = localStorage.getItem(`hideModal_${today}`);
    setDontShowToday(storedCheckboxState === 'true');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const todayTasks = tasks.filter(task => {
      const taskStart = new Date(task.taskStart.split(' ')[0]);
      const taskEnd = new Date(task.taskEnd.split(' ')[0]);
      const todayDate = new Date(today);
  
      return taskStart <= todayDate && todayDate <= taskEnd;
    });
  
    setTodaysTasks(todayTasks);
  
    if (!hasInitializedTodayTaskModal && localStorage.getItem(`hideModal_${today}`) !== 'true') {
      if (todayTasks.length > 0) {
        setShowTodayTaskModal(true);
        setHasInitializedTodayTaskModal(true);
      }
    }
  }, [tasks, today, hasInitializedTodayTaskModal]);

  useEffect(() => {
    const storedCheckboxState = localStorage.getItem(`hideModal_${today}`);
    
    const storedDate = localStorage.getItem('lastCheckedDate');
    if (storedDate && storedDate !== today) {
      localStorage.removeItem(`hideModal_${storedDate}`);
    }
    localStorage.setItem('lastCheckedDate', today);
  
    setDontShowToday(storedCheckboxState === 'true');
  }, [today]);

  const handleModalClose = () => {
    if (dontShowToday) {
      localStorage.setItem(`hideModal_${today}`, 'true');
    } else {
      localStorage.removeItem(`hideModal_${today}`);
    }
    setShowTodayTaskModal(false);
  };

  useEffect(() => {
    const calendarContainer = calendarRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      calendarContainer.classList.add('active');
      startX = e.pageX - calendarContainer.offsetLeft;
      scrollLeft = calendarContainer.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
      calendarContainer.classList.remove('active');
    };

    const handleMouseUp = () => {
      isDown = false;
      calendarContainer.classList.remove('active');
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - calendarContainer.offsetLeft;
      const walk = (x - startX) * 2;
      calendarContainer.scrollLeft = scrollLeft - walk;
    };

    calendarContainer.addEventListener('mousedown', handleMouseDown);
    calendarContainer.addEventListener('mouseleave', handleMouseLeave);
    calendarContainer.addEventListener('mouseup', handleMouseUp);
    calendarContainer.addEventListener('mousemove', handleMouseMove);

    return () => {
      calendarContainer.removeEventListener('mousedown', handleMouseDown);
      calendarContainer.removeEventListener('mouseleave', handleMouseLeave);
      calendarContainer.removeEventListener('mouseup', handleMouseUp);
      calendarContainer.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const openModalWithTask = (task) => {
    setSelectedTask(task);
    setShowModal(true);
  };

  const parseDateString = (dateString) => {
    return new Date(dateString.replace(' ', 'T'));
  };

  const daysInMonth = [];
  const daysInSelectedMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
  for (let day = 1; day <= daysInSelectedMonth; day++) {
    const date = new Date(selectedYear, selectedMonth, day);
    const dayName = dayNames[date.getDay()];
    daysInMonth.push({
      date: day,
      dayName: dayName,
      fullDate: date,
    });
  }

  const tasksInMonth = tasks?.filter((task) => {
    const taskStartDate = parseDateString(task.taskStart);
    const taskEndDate = parseDateString(task.taskEnd);

    const taskStartMonth = taskStartDate.getMonth();
    const taskEndMonth = taskEndDate.getMonth();

    const taskStartYear = taskStartDate.getFullYear();
    const taskEndYear = taskEndDate.getFullYear();

    return (
      (taskStartYear === selectedYear || taskEndYear === selectedYear) &&
      (taskStartMonth === selectedMonth || taskEndMonth === selectedMonth)
    );
  });

  const handleDefaultTextChange = (event) => {
    setBoardTitle(event.target.value);
  };

  const handleDefaultTextBlur = () => {
    if (selectedBoard.boardTitle !== boardTitle) {
      handleUpdateBoardTitle(selectedBoard.boardId, boardTitle);
    }
    setIsEditing(false);
  };

  const handleDefaultTextClick = () => {
    setIsEditing(true);
  };

  const handleOpenDeleteConfirmModal = () => {
    setShowBoardDeleteModal(true);
  };

  const handleAcceptDelete = () => {
    handleDeleteBoard(selectedBoard.boardId);
  };

  const handleDeclineDelete = () => {
    setShowBoardDeleteModal(false);
  };

  return (
    <>
      <div className="month-selector">
        <div style={{ width: '100%' }}>
          <div
            onClick={handleDefaultTextClick}
            style={{ marginTop: '10px', cursor: 'pointer', marginBottom: '10px', width: '100%' }}
          >
            {isEditing ? (
              <input
                type="text"
                value={boardTitle}
                onChange={handleDefaultTextChange}
                onBlur={handleDefaultTextBlur}
                autoFocus
                maxLength={30}
              />
            ) : (
              <span style={{ alignItems: 'center', display: 'flex', gap: '10px' }}>{boardTitle} <MdModeEdit /></span>
            )}
          </div>

          <div style={{ flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <p className='page-title'>{getTranslation('date')}: </p>
              <select id="month" style={{ marginLeft: '15px' }} value={selectedMonth} onChange={handleMonthChange}>
                {monthNames.map((month, index) => (
                  <option key={index} value={index}>
                    {month}
                  </option>
                ))}
              </select>
              <select id="year" style={{ marginLeft: '15px' }} value={selectedYear} onChange={handleYearChange}>
                {Array.from({ length: 21 }, (_, i) => currentYear - 10 + i).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ flexDirection: 'row', display: 'flex', gap: '10px' }}>
              {todaysTasks.length > 0 && <button onClick={() => setShowTodayTaskModal(true)}>{getTranslation('todayTasks')}</button>}
              <button className='button-cancel' onClick={handleOpenDeleteConfirmModal}>{getTranslation('removeBoard')}</button>
              <button onClick={() => setShowAddTaskModal(true)} style={{ alignItems: 'center', display: 'flex', gap: '10px' }}><IoAddSharp style={{ fontSize: '16px' }} />{getTranslation('newTask')}</button>
            </div>
          </div>
        </div>
      </div>

      <div className='card' style={{ maxHeight: '75%', height: '75%' }}>
        <div className="calendar-container" ref={calendarRef}>
          <div className="calendar-grid">
            {daysInMonth.map((dayData, index) => (
              <div key={index} className="calendar-day">
                <div className="day-name">
                  <div style={{ fontSize: '16px', fontWeight: '600' }}>{dayData.date}</div>
                  <div style={{ fontSize: '11px', color: '#9191AA' }}>{dayData.dayName}</div>
                </div>
              </div>
            ))}
          </div>

          <div className="task-container">
            {tasksInMonth?.map((task, taskIndex) => {
              const taskStartDay = parseDateString(task.taskStart).getMonth() === selectedMonth
                ? parseDateString(task.taskStart).getDate()
                : 1;

              const taskEndDay = parseDateString(task.taskEnd).getMonth() === selectedMonth
                ? parseDateString(task.taskEnd).getDate()
                : daysInSelectedMonth;

              const startIndex = taskStartDay - 1;
              const spanDays = taskEndDay - taskStartDay + 1;
              return (
                <React.Fragment key={taskIndex}>
                  <div
                    className="task-slot"
                    style={{ gridColumn: `${startIndex + 1} / span ${spanDays}` }}
                    onClick={() => openModalWithTask(task)}
                  >
                    <div>
                      {task.taskTitle}
                    </div>
                    <div style={{ width: '100%', display: 'flex', fontSize: '10px' }}>
                      {parseDateString(task.taskStart).getDate()} - {parseDateString(task.taskEnd).getDate()} {monthNames[selectedMonth]}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>

      <TodayTaskModal isOpen={showTodayTaskModal} onClose={handleModalClose} tasks={todaysTasks} onCheckboxChange={(checked) => setDontShowToday(checked)} isCheckboxChecked={dontShowToday} taskOnClick={(task) => openModalWithTask(task)} />
      {showModal && <TaskDetailModal isOpen={showModal} onClose={() => setShowModal(false)} task={selectedTask} />}
      <AddTaskModal isOpen={showAddTaskModal} onClose={() => setShowAddTaskModal(false)} />
      <MessageBox show={showBoardDeleteModal} title={getTranslation('areYouSure')} message={getTranslation('boardDeleteWarning')} onConfirm={handleAcceptDelete} onClose={handleDeclineDelete} />
    </>
  );
};

export default Calendar;