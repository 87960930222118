import React, { Suspense, useState, useEffect, useContext } from 'react';
import './Login.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLanguage } from '../../LanguageProvider';
import { Link, useNavigate } from 'react-router-dom';
import LanguageSelect from '../../components/LanguageSelect';
import ThreeSisters from '../../components/ThreeSisters/ThreeSisters';
import Logo from '../../assets/ApiollonLogo.png'
import Services from '../../services/Services';
import { GeneralContext } from '../../GeneralProvider';
import { useToaster } from '../../ToasterProvider';

export default function Login() {

  const navigate = useNavigate();
  const { getTranslation } = useLanguage();
  const { handleTokenUpdate } = useContext(GeneralContext);
  const { toastError } = useToaster();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState("");

  const handleLogin = () => {
    Services.UserLogin(token, username, password)
      .then((res) => {
        if (res.data.status) {
          if (rememberMe) {
            localStorage.setItem('username', username);
            localStorage.setItem('password', password);
            localStorage.setItem('user', JSON.stringify(res.data.result));
            localStorage.setItem('token', res.data.result.token);
          } else {
            sessionStorage.setItem('user', JSON.stringify(res.data.result));
            sessionStorage.setItem('token', res.data.result.token);
          }
          handleTokenUpdate(res.data.result.token)

          navigate('/home');
        }
        else {
          toastError(getTranslation('invalidCredentials'))
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAutoLogin = (token, username, password) => {
    Services.UserLogin(token, username, password)
      .then((res) => {
        if (res.data.status) {
          handleTokenUpdate(res.data.result.token)
          navigate('/home');
        }
        else {
          toastError(getTranslation('invalidCredentials'))
        }
      })
      .catch((err) => console.log(err));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getToken = () => {
    Services.GetToken()
      .then((res) => {
        if (res.data.status) {
          setToken(res.data.result.token);
          const loginUserName = localStorage.getItem('username')
          const loginPassword = localStorage.getItem('password')
          const loginToken = res.data.result.token
          if (loginUserName && loginPassword && loginToken) {
            handleAutoLogin(loginToken, loginUserName, loginPassword)
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getToken()
  }, []);

  return (
    <div className='login-main'>

      {/* {errorMessage && (
        <Toaster
          message={errorMessage}
          type="error"
          position={{ top: '20px', right: '20px' }}
          onClose={() => setErrorMessage("")}
        />
      )} */}

      <Suspense fallback={null}>
        <ThreeSisters />
      </Suspense>

      <div style={{ position: 'absolute', top: 10, right: 15, zIndex: 99 }}>
        <LanguageSelect />
      </div>

      <img
        className='img'
        src={Logo}
        alt={"Logo"}
        style={{ zIndex: 99, marginBottom: '25px' }}
      />
      <div className='login-card' style={{ zIndex: 99 }}>
        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <p style={{ fontSize: '24px' }}>{getTranslation('login')}</p>
        </div>

        <div className='label-input' style={{ flexDirection: 'row', display: 'flex' }}>
          <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('username')} / {getTranslation('email')} :</p>
          <div className='input-container'>
            <input
              className='input-text'
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}  // Enter tuşu için
              style={{ width: '350px' }}
              placeholder={getTranslation('username') + " / " + getTranslation('email')}
            />
          </div>
        </div>

        <div className='label-input' style={{ marginTop: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('password')} :</p>
          <div className='input-container' style={{ display: 'flex', alignItems: 'center', width: '350px', position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}  // Enter tuşu için
              style={{ width: '100%', paddingRight: '30px' }}
              placeholder={getTranslation('password')}
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '10px',
                cursor: 'pointer',
                color: '#999',
              }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex' }}>
          <div style={{ width: '200px' }}></div>
          <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center', width: '350px', justifyContent: 'space-between' }}>
            <label style={{ fontSize: '12px', fontWeight: '400', color: 'inherit', display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              {getTranslation('rememberMe')}
            </label>
            <Link style={{ fontSize: '12px', fontWeight: '400', color: 'inherit', textAlign: 'end' }}>
              {getTranslation('forgotPassword')}
            </Link>
          </div>
        </div>

        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '35px', flexDirection: 'column', gap: '10px' }}>
          <button onClick={handleLogin} style={{ width: '100%' }}>{getTranslation('login')}</button>
          <Link to={'/signup'} style={{ fontSize: '12px', fontWeight: '400', color: 'inherit', textAlign: 'end' }}>
            {getTranslation('signup')}
          </Link>
        </div>
      </div>
    </div>
  );
}
