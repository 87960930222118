import React, { useContext, useEffect, useState } from 'react';
import '../../Layout/Layout.css';
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { convertToHTML } from 'draft-convert';
import { useLanguage } from '../../LanguageProvider';
import RichTextEditor from '../../components/RichTextEditor';
import { GeneralContext } from '../../GeneralProvider';
import { FaPlus } from "react-icons/fa6";
import './DocumentationPage.css';

export default function DocumentationPage() {
    const { getTranslation } = useLanguage();
    const { selectedDocumentation, handleUpdateDocumentation } = useContext(GeneralContext);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [parameters, setParameters] = useState();
    const [editMode, setEditMode] = useState(false);
    const [method, setMethod] = useState(selectedDocumentation?.parameters?.method);
    const [editBaseURL, setEditBaseURL] = useState("");
    const [editEndpoint, setEditEndpoint] = useState("");

    const { baseUrl, endpoint } = selectedDocumentation?.parameters?.url ? extractBaseUrlAndEndpoint(selectedDocumentation?.parameters?.url) : { baseUrl: '-', endpoint: '-' };

    const checkType = (value) => {
        if (Array.isArray(value)) {
            return "Array";
        } else if (typeof value === "string") {
            return "String";
        } else {
            return "Other";
        }
    };

    useEffect(() => {
        setEditMode(false)
        if (Object.keys(selectedDocumentation).length > 0) {

            if (checkType(selectedDocumentation?.parameters?.body) === "String") {
                setParameters(extractParamsAndTypes((selectedDocumentation?.parameters?.body)));
            }
            else if (checkType(selectedDocumentation?.parameters?.body) === "Array") {
                setParameters(selectedDocumentation?.parameters?.body)
            }

            setEditBaseURL(extractBaseUrlAndEndpoint(selectedDocumentation?.parameters?.url).baseUrl)
            setEditEndpoint(extractBaseUrlAndEndpoint(selectedDocumentation?.parameters?.url).endpoint)

            const blocksFromHTML = convertFromHTML(selectedDocumentation.description);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            setEditorState(EditorState.createWithContent(contentState));
        }

    }, [selectedDocumentation]);

    const handleMethodChange = (event) => {
        setMethod(event.target.value);
    };

    const getOptionStyle = (option) => {
        switch (option) {
            case 'GET':
                return { color: '#3fc462', fontWeight: '600' };
            case 'POST':
                return { color: '#d9db40', fontWeight: '600' };
            case 'PUT':
                return { color: '#3c6ad6', fontWeight: '600' };
            case 'DELETE':
                return { color: '#e3a6b4', fontWeight: '600' };
            default:
                return {};
        }
    };

    function extractParamsAndTypes(body) {
        function getType(value) {
            if (typeof value === 'number') {
                return Number.isInteger(value) ? 'Number (Integer)' : 'Number (Float)';
            }
            if (typeof value === 'string') {
                return 'String';
            }
            if (typeof value === 'boolean') {
                return 'Boolean';
            }
            if (Array.isArray(value)) {
                const itemType = value.length > 0 ? getType(value[0]) : 'Unknown';
                return `Array (${itemType})`;
            }
            if (value === null) {
                return 'Null';
            }
            if (typeof value === 'object') {
                return 'Object';
            }
            return 'Unknown';
        }

        function processObject(obj, parentKey = '') {
            const result = [];

            for (const [key, value] of Object.entries(obj)) {
                const fullKey = parentKey ? `${parentKey}.${key}` : key;
                const type = getType(value);

                if (type === 'Object') {
                    result.push({ key: fullKey, type: 'Object', description: '', required: false });
                    result.push(...processObject(value, fullKey));
                } else if (type.startsWith('Array')) {
                    result.push({ key: fullKey, type: type, description: '', required: false });
                    if (value.length > 0 && typeof value[0] === 'object') {
                        result.push(...processObject(value[0], fullKey));  // Process the structure of the first item
                    }
                } else {
                    result.push({ key: fullKey, type: type, description: '', required: false });
                }
            }

            return result;
        }

        try {
            const parsedBody = JSON.parse(body);
            return processObject(parsedBody);
        } catch (error) {
            console.error("Invalid JSON provided:", error);
            return [];
        }
    }

    function handleInputChange(index, field, value) {
        const updatedParameters = [...parameters];
        updatedParameters[index] = { ...updatedParameters[index], [field]: value };
        setParameters(updatedParameters);
    }

    function handleAddParameter() {
        setParameters([...parameters, { key: '', type: 'String', description: '', required: false }]);
    }

    function handleDeleteParameter(index) {
        setParameters(parameters.filter((_, i) => i !== index));
    }

    function extractBaseUrlAndEndpoint(url) {
        const parsedUrl = new URL(url);
        const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
        const endpoint = parsedUrl.pathname;

        return { baseUrl, endpoint };
    }

    const handleEditBaseURLChange = (event) => {
        setEditBaseURL(event.target.value);
    }


    const handleEditEndpointChange = (event) => {
        setEditEndpoint(event.target.value);
    }

    const handleEditButton = () => {
        if (editMode) {

            
            setEditBaseURL(extractBaseUrlAndEndpoint(selectedDocumentation?.parameters?.url).baseUrl)
            setEditEndpoint(extractBaseUrlAndEndpoint(selectedDocumentation?.parameters?.url).endpoint)

            const blocksFromHTML = convertFromHTML(selectedDocumentation.description);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            setEditorState(EditorState.createWithContent(contentState));

            setEditMode(false)
            setParameters(extractParamsAndTypes((selectedDocumentation?.parameters?.body)));
            setMethod(selectedDocumentation?.parameters?.method)
            setEditBaseURL(extractBaseUrlAndEndpoint(selectedDocumentation?.parameters?.url).baseUrl)
            setEditEndpoint(extractBaseUrlAndEndpoint(selectedDocumentation?.parameters?.url).endpoint)
            setEditMode(false)
            setParameters(extractParamsAndTypes((selectedDocumentation?.parameters?.body)));
            setMethod(selectedDocumentation?.parameters?.method)
            setEditBaseURL(extractBaseUrlAndEndpoint(selectedDocumentation?.parameters?.url).baseUrl)
            setEditEndpoint(extractBaseUrlAndEndpoint(selectedDocumentation?.parameters?.url).endpoint)
        }
        else {
            setEditMode(true)
            //Kaydetme işlemi
        }
    }

    const handleSave = () => {

        const contentState = editorState.getCurrentContent();
        let description = convertToHTML(contentState);

        let updateData = {
            parameters: { body: parameters, method: method, title: selectedDocumentation.title, url: editBaseURL + editEndpoint },
            description: description,
            // title:selectedDocumentation.title
        }
        handleUpdateDocumentation(selectedDocumentation.apiId, selectedDocumentation.id, updateData)

        setEditMode(false)
    }

    return (
        <div className='main-page'>
            <div style={{ width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p className='page-title'>{selectedDocumentation?.parameters?.title}</p>
                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <button onClick={handleEditButton}>
                        {editMode ? getTranslation('cancel') : getTranslation('edit')}
                    </button>
                    {editMode &&
                        <button onClick={handleSave} style={{ height: '35px' }}>
                            {getTranslation('save')}
                        </button>
                    }

                </div>
            </div>

            {Object.keys(selectedDocumentation).length !== 0 ? (
                <>
                    {editMode ?
                        <div style={{ marginBottom: '15px' }}>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                <p style={{ fontWeight: '600', width: '10%' }}>METHOD:</p>
                                <span >
                                    <select value={method} onChange={handleMethodChange} className="custom-select">
                                        <option style={getOptionStyle('GET')} value="GET">GET</option>
                                        <option style={getOptionStyle('POST')} value="POST">POST</option>
                                        <option style={getOptionStyle('PUT')} value="PUT">PUT</option>
                                        <option style={getOptionStyle('DELETE')} value="DELETE">DELETE</option>
                                    </select>
                                </span>
                            </div>
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <p style={{ fontWeight: '600', width: '10%' }}>BASE URL: </p>
                                <span style={{ width: '50%' }}>
                                    <input
                                        type="text"
                                        value={editBaseURL}
                                        onChange={handleEditBaseURLChange}
                                    />
                                </span>
                            </div>

                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <p style={{ fontWeight: '600', width: '10%' }}>ENDPOINT: </p>
                                <span style={{ width: '50%' }}>
                                    <input
                                        type="text"
                                        value={editEndpoint}
                                        onChange={handleEditEndpointChange}
                                    />
                                </span>
                            </div>
                        </div>
                        :
                        <>
                            <p style={{ fontWeight: '600' }}>METHOD: <span style={{ fontWeight: '400' }}>{selectedDocumentation?.parameters?.method || "-"}</span></p>
                            <p style={{ fontWeight: '600' }}>BASE URL: <span style={{ fontWeight: '400' }}>{baseUrl}</span></p>
                            <p style={{ fontWeight: '600' }}>ENDPOINT: <span style={{ fontWeight: '400' }}>{endpoint}</span></p>
                        </>
                    }


                    {editMode ? (
                        <RichTextEditor editorState={editorState} setEditorState={setEditorState} />
                    ) : (
                        <div className='card' dangerouslySetInnerHTML={{ __html: selectedDocumentation.description || getTranslation('noDescription') }} />
                    )}

                    <p style={{ marginTop: '25px' }}>{getTranslation('parameters')}</p>

                    <div className='card' style={{ maxHeight: '35%', overflowY: 'auto' }}>

                        {editMode &&
                            <button style={{ alignItems: 'center', display: 'flex', marginBottom: '15px' }} onClick={handleAddParameter}>
                                <FaPlus style={{ marginRight: '10px' }} /> {getTranslation('addParameter')}
                            </button>
                        }


                        <div className='card' style={{ maxHeight: '320px', overflowY: 'auto', boxShadow: 'none' }}>
                            <div className="params-container">
                                {editMode ?
                                    <>
                                        {parameters?.map((param, index) => (
                                            <div key={index} className="param-item-row">
                                                <input
                                                    type="text"
                                                    placeholder={getTranslation('name')}
                                                    value={param.key}
                                                    onChange={(e) => handleInputChange(index, 'key', e.target.value)}
                                                    className='param-input'
                                                />
                                                <select
                                                    value={param.type}
                                                    onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                                                    className='param-input'
                                                >
                                                    <option value="Number (Integer)">Number (Integer)</option>
                                                    <option value="Number (Float)">Number (Float)</option>
                                                    <option value="String">String</option>
                                                    <option value="Boolean">Boolean</option>
                                                    <option value="Array (Number (Integer))">Array (Number (Integer))</option>
                                                    <option value="Array (Number (Float))">Array (Number (Float))</option>
                                                    <option value="Array (String)">Array (String)</option>
                                                    <option value="Array (Object)">Array (Object)</option>
                                                    <option value="Object">Object</option>
                                                    <option value="Null">Null</option>
                                                    <option value="Unknown">Unknown</option>
                                                </select>
                                                <input
                                                    type="text"
                                                    placeholder={getTranslation('description')}
                                                    value={param.description}
                                                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                                    className='param-input'
                                                />
                                                <label style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', display: 'flex', fontSize: '12px', cursor: 'pointer' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={param.required}
                                                        onChange={(e) => handleInputChange(index, 'required', e.target.checked)}
                                                        className='param-input'
                                                    />
                                                    {getTranslation('required')}
                                                </label>
                                                <button onClick={() => handleDeleteParameter(index)} className="delete-button">
                                                    {getTranslation('delete')}
                                                </button>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <>
                                        {parameters?.map((param, index) => (
                                            <div key={index} className="param-item-row">
                                                <label style={{ width: '45%', fontSize: '14px', wordBreak: 'break-all' }}>{param.key}</label>
                                                <label style={{ width: '5%', fontSize: '14px' }}>{param.type}</label>
                                                <label style={{ width: '25%', fontSize: '14px' }}>{param.description}</label>
                                                {param.required ? <label style={{ width: '25%', fontSize: '14px' }}>{getTranslation('required')}</label> : <label style={{ width: '25%' }}></label>}
                                            </div>
                                        ))}
                                    </>
                                }

                            </div>
                        </div>

                    </div>
                </>
            ) : (
                <div style={{ width: '100%', height: '65%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <p>{getTranslation('selectCollection')}</p>
                </div>
            )
            }
        </div >
    );
}
