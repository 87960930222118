import React, { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { useLanguage } from "../../../LanguageProvider";
import "./AddManualModal.css";

export default function AddManualModal({ isOpen, onClose, onAdd }) {
  const { getTranslation } = useLanguage();
  const [title, setTitle] = useState("");
  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");

  const handleAdd = () => {
    onAdd({ title, startTime, endTime });
    onClose();
    setTitle("");
    setStartTime("00:00");
    setEndTime("00:00");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} position={"middle"}>
      <div
        style={{
          marginTop: "15px",
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        {getTranslation("addManual")}
      </div>

      {/* Görev başlığı için input alanı */}
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={getTranslation("whatAreYouWorkingOn")}
        style={{
          width: "100%",
          marginTop: "10px",
          padding: "8px",
          border: "1px solid #a5a5a5",
          borderRadius: "5px",
          backgroundColor: "#2b2f38",
          color: "#a5a5a5",
        }}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          marginTop: "15px",
        }}
      >
        {/* Start Time */}
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <label style={{ marginRight: "8px" }}>
            {getTranslation("startTime")}:
          </label>
          {/* <FiEdit3 style={{ marginRight: "5px", cursor: "pointer" }} /> */}
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            style={{
              width: "100%",
              padding: "5px",
              border: "1px solid #a5a5a5",
              borderRadius: "5px",
              backgroundColor: "transparent",
              color: "#a5a5a5",
              textAlign: "center",
            }}
          />
        </div>

        <div
          style={{
            width: "1px",
            height: "30px",
            backgroundColor: "#a5a5a5",
            margin: "0 20px",
          }}
        ></div>

        {/* End Time */}
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <label style={{ marginRight: "8px" }}>
            {getTranslation("endTime")}:
          </label>
          <input
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            style={{
              width: "100%",
              padding: "5px",
              border: "1px solid #a5a5a5",
              borderRadius: "5px",
              backgroundColor: "transparent",
              color: "#a5a5a5",
              textAlign: "center",
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          marginTop: "20px",
        }}
      >
        <button
          onClick={handleAdd}
          style={{
            padding: "8px 16px",
            backgroundColor: "#d8681d",
            color: "white",
            border: "none",
            borderRadius: "5px",
            width: "80px",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          {getTranslation("add")}
        </button>
        <button
          onClick={onClose}
          style={{
            padding: "8px 16px",
            backgroundColor: "#555",
            color: "white",
            border: "none",
            borderRadius: "5px",
            width: "80px",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          {getTranslation("close")}
        </button>
      </div>
    </Modal>
  );
}
