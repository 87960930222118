import React, { createContext, useState, useEffect, useContext } from "react";
import en from "./locales/en.json";
import tr from "./locales/tr.json";
import es from "./locales/es.json";
import de from "./locales/de.json";
import it from "./locales/it.json";
import fr from "./locales/fr.json";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const translations = {
    en,
    tr,
    es,
    de,
    it,
    fr,
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setLanguage(savedLanguage);
    } else {
      setLanguage("en");
      localStorage.setItem("language", "en");
    }
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  // const getTranslation = (key) => {
  //     if (translations[language]) {
  //         return translations[language][key] || key;
  //     }
  //     return key; // Varsayılan olarak anahtar değeri döndürmek
  // };

  const getTranslation = (key, params = {}) => {
    if (translations[language]) {
      let translation = translations[language][key] || key;

      Object.keys(params).forEach((paramKey) => {
        translation = translation.replace(
          new RegExp(`\\{${paramKey}\\}`, "g"),
          params[paramKey]
        );
      });

      return translation;
    }
    return key;
  };

  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, translations, getTranslation }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
