import React, { useContext, useState } from 'react';
import { Controlled as ControlledEditor } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/darcula.css';
import 'codemirror/theme/elegant.css';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/edit/matchbrackets';
import { ThemeContext } from '../ThemeProvider';
import { JSONTree } from 'react-json-tree';


export default function JsonEditor({ code, setCode, readOnly }) {
    const { darkMode } = useContext(ThemeContext);
    const [activeTab, setActiveTab] = useState("text");

    const JsonTreeView = ({ json }) => {
        const jsonTreeTheme = darkMode
            ? {
                scheme: 'monokai',
                author: 'TextMate',
                base00: '#272822',
                base01: '#383830',
                base02: '#49483e',
                base03: '#75715e',
                base04: '#a59f91',
                base05: '#f8f8f2',
                base06: '#f5f4f1',
                base07: '#f9f8f5',
                base08: '#f92672',
                base09: '#CC7832',
                base0A: '#f4bf75',
                base0B: '#CC7832',
                base0C: '#a1efe4',
                base0D: '#6A8759',
                base0E: '#ae81ff',
                base0F: '#cc6633',
            }
            : {
                scheme: 'solarized',
                author: 'Solarized',
                base00: '#fdf6e3',
                base01: '#eee8d5',
                base02: '#93a1a1',
                base03: '#657b83',
                base04: '#586e75',
                base05: '#002b36',
                base06: '#073642',
                base07: '#000000',
                base08: '#dc322f',
                base09: '#cb4b16',
                base0A: '#b58900',
                base0B: '#859900',
                base0C: '#2aa198',
                base0D: '#268bd2',
                base0E: '#d33682',
                base0F: '#d33682',
            };

        return (
            <JSONTree data={json} theme={jsonTreeTheme} />
        );
    };

    const handleChange = (editor, data, value) => {
        if (!readOnly) {
            setCode(value);
        }
    };

    const editorOptions = {
        lineNumbers: true,
        mode: 'application/json',
        theme: darkMode ? 'darcula' : 'elegant',
        lineWrapping: true,
        readOnly: readOnly,
        foldGutter: true,
        matchBrackets: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
    };

    let json;
    try {
        json = JSON.parse(code);
    } catch (e) {
        json = {};
    }

    function formatJSONString(jsonString) {
        try {
            // JSON stringini parse ederek obje yapısına dönüştür
            const jsonObject = JSON.parse(jsonString);
            // Obje yapısını girintili string formatına dönüştür
            return JSON.stringify(jsonObject, null, 2); // 2 boşluklu girinti
        } catch (error) {
            console.error("Geçersiz JSON formatı:", error);
            setCode(jsonString); // Eğer hata varsa orijinal stringi döndür
        }
    }
    console.log(code);
    return (
        <div style={{ maxWidth: '100%', marginTop: '15px', marginBottom: '15px' }}>
            <div className="tabs" style={{ marginBottom: '15px' }}>
                <button
                    className={`tab ${activeTab === 'text' ? 'active' : ''}`}
                    onClick={() => setActiveTab('text')}
                >
                    JSON
                </button>
                <button
                    className={`tab ${activeTab === 'tree' ? 'active' : ''}`}
                    onClick={() => setActiveTab('tree')}
                >
                    Tree
                </button>
            </div>

            {activeTab === "text" &&
                <>
                    <button onClick={formatJSONString}>Format</button>
                    <div className='card'>
                        <ControlledEditor
                            value={code}
                            options={editorOptions}
                            onBeforeChange={handleChange}
                            onChange={handleChange}
                        />
                    </div>
                </>

            }

            {activeTab === "tree" &&
                <div className='card'>
                    <JsonTreeView json={json} />
                </div>
            }

        </div>
    );
}
