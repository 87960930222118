import React, { createContext, useState, useEffect, useContext } from "react";
import { GeneralContext } from "./GeneralProvider";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const { handleThemeChange } = useContext(GeneralContext);
  const [selectedTheme, setSelectedTheme] = useState(() => {
    // localStorage'dan temayı oku, yoksa varsayılan olarak 'dark' kullan
    const savedTheme = localStorage.getItem("selectedTheme");
    return savedTheme || "dark"; // Eğer yoksa default olarak 'dark'
  });

  const sessionUser = JSON.parse(sessionStorage.getItem("user"));
  const localUser = JSON.parse(localStorage.getItem("user"));

  const user = sessionUser ? sessionUser : localUser;

  const themes = [
    "dark",
    "light",
    "arcade",
    "matrix",
    "tron",
    "interstellar",
    "bladerunner",
    "terminator",
    "steampunk",
    "barbie",
    "pokemon",
    "star-wars",
    "ex-machina",
    "inception",
    "jurassic-park",
    "godfather",
    "scarface",
    "joker",
    "batman",
    "superman",
    "wonderwoman",
    "dedekorkut",
    "martianmanhunter",
    "gothamcity",
    "valhalla",
    "valkyrie",
    "yggdrasil",
    "the-mask",
    "harry-potter",
    "tasavvuf",
    "miami-vice",
    "plaguetale",
    "binbir-gece",
    "streetfighter",
  ]; // Kullanılabilir temalar

  const changeTheme = (theme) => {
    if (themes.includes(theme)) {
      handleThemeChange(theme);
      setSelectedTheme(theme);
      localStorage.setItem("selectedTheme", theme); // Yeni temayı localStorage'a kaydet
    } else {
      console.warn(`Theme "${theme}" is not available.`);
    }
  };

  useEffect(() => {
    if (user && !selectedTheme) setSelectedTheme(user?.theme);
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde temayı ayarla
    document.documentElement.setAttribute("data-theme", selectedTheme);
  }, [selectedTheme]);

  return (
    <ThemeContext.Provider value={{ selectedTheme, changeTheme, themes }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
