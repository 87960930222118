// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.params-container {
  display: grid;
  gap: 15px;
}

.param-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.param-input {
  padding: 8px;
  margin-right: 10px;
}

.param-item-row label {
  margin-left: 5px;
}

.delete-button {
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}

@media (max-width: 1655px) {
  .params-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Kronos/Kronos.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":[".params-container {\n  display: grid;\n  gap: 15px;\n}\n\n.param-item-row {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.param-input {\n  padding: 8px;\n  margin-right: 10px;\n}\n\n.param-item-row label {\n  margin-left: 5px;\n}\n\n.delete-button {\n  color: white;\n  border: none;\n  padding: 5px 10px;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n  font-size: 14px;\n  cursor: pointer;\n  margin-left: 10px;\n}\n\n@media (max-width: 1655px) {\n  .params-container {\n    grid-template-columns: repeat(1, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
