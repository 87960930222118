import React, { useContext, useState } from 'react'
import { useLanguage } from '../../../LanguageProvider';
import countryCodes from '../../Signup/countryCodes.json'
import CustomPhoneSelect from '../../../components/CustomPhoneSelect/CustomPhoneSelect';
import { GeneralContext } from '../../../GeneralProvider';
import { useNavigate } from 'react-router-dom';
import MessageBox from '../../../components/MessageBox/MessageBox';
import '../ProfilePage.css'

export default function ProfileInfo() {
    const navigate = useNavigate();
    const { getTranslation } = useLanguage();
    const { handleUpdateUser, handlePageChange } = useContext(GeneralContext);

    const sessionUser = JSON.parse(sessionStorage.getItem('user'));
    const localUser = JSON.parse(localStorage.getItem('user'));

    const user = localUser ? localUser : sessionUser;

    const [name, setName] = useState(user.name);
    const [lastName, setLastName] = useState(user.lastname);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [countryCode, setCountryCode] = useState("+90");

    const [errors, setErrors] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const validateInputs = () => {
        const newErrors = [];

        // Name validation
        if (name.length < 2 || name.length > 50 || /[^a-zA-Z' ]/g.test(name)) {
            newErrors.push('nameError');
        }

        // Lastname validation
        if (lastName.length < 1 || lastName.length > 50 || /[^a-zA-Z']/g.test(lastName)) {
            newErrors.push('lastnameError');
        }

        // Phone validation
        if (!/^\d{10}$/.test(phone)) {
            newErrors.push('phoneError');
        }

        // Email validation
        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
            newErrors.push('emailError');
        }

        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const updateUser = () => {
        setShowModal(false)
        if (!validateInputs()) return;
        const updatedName = name !== user.name ? name : undefined;
        const updatedLastName = lastName !== user.lastname ? lastName : undefined;
        const updatedEmail = email !== user.email ? email : undefined;
        const updatedPhone = phone !== user.phone ? phone : undefined;
        const updatedCountryCode = countryCode !== user.countryCode ? countryCode : undefined;

        handleUpdateUser(user.id, updatedName, updatedLastName, updatedEmail, updatedPhone, updatedCountryCode)
            .then((isSuccess) => {
                if (isSuccess) {
                    handlePageChange('collections')
                    localStorage.removeItem('username');
                    localStorage.removeItem('password');
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('user')
                    navigate('/');
                }
            })
    }

    return (
        <div className='main-page'>
            <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                <div className='profile-card'>
                    <p style={{ fontSize: '16px', marginBottom: '35px' }}>{getTranslation('profileInfo')}</p>

                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('name')} :</p>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            style={{ width: '350px' }}
                            placeholder={getTranslation('name')}
                        />
                    </div>

                    <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex' }}>
                        <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('lastname')} :</p>
                        <input
                            type="text"
                            id="lastname"
                            name="lastname"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            style={{ width: '350px' }}
                            placeholder={getTranslation('lastname')}
                        />
                    </div>

                    <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex' }}>
                        <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('email')} :</p>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ width: '350px' }}
                            placeholder={getTranslation('email')}
                        />
                    </div>

                    <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('phone')} :</p>
                        <div style={{ display: 'flex', alignItems: 'center', width: '350px' }}>
                            {/* Ülke kodu select input */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomPhoneSelect
                                    themeSensitive={true}
                                    options={countryCodes}
                                    value={countryCode}
                                    onChange={setCountryCode}
                                />
                            </div>

                            {/* Telefon numarası input */}
                            <input
                                id="phone"
                                name="phone"
                                value={phone}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) { // Sadece rakamları kabul et
                                        setPhone(value);
                                    }
                                }}
                                style={{ width: '260px' }}
                                placeholder={getTranslation('phone')}
                                maxLength={10}
                            />

                        </div>
                    </div>

                    {errors.length > 0 && (
                        <div className='error-messages'>
                            {errors.map((error, index) => (
                                <p key={index} style={{ color: 'red', fontSize: '14px' }}>- {getTranslation(error)}</p>
                            ))}
                        </div>
                    )}

                    <button onClick={() => setShowModal(true)} style={{ marginTop: '45px', width: '550px' }}>{getTranslation('save')}</button>

                </div>

                <MessageBox show={showModal} title={getTranslation('areYouSure')} message={getTranslation('saveChangesConfirmation')} onConfirm={updateUser} onClose={() => setShowModal(false)} />
            </div>
        </div>

    )
}
