import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import { useLanguage } from '../../../LanguageProvider';
import { GeneralContext } from '../../../GeneralProvider';

export default function AddTaskModal({ isOpen, onClose }) {
    const { getTranslation } = useLanguage();
    const { handleAddTask } = useContext(GeneralContext);

    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [title, setTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    const handleCloseModal = () => {
        setStartDate('');
        setStartTime('');
        setEndDate('');
        setEndTime('');
        setTitle('');
        setSubtitle('');
        setDescription('');
        setError('');
        onClose();
    };

    const handleValidation = () => {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        const endDateTime = new Date(`${endDate}T${endTime}:00`);

        const formattedStartDateTime = `${startDate}T${startTime}`
        const formattedEndDateTime = `${endDate}T${endTime}:00`

        if (endDateTime < startDateTime) {
            setError(getTranslation('endDateTimeError'));
        } else {
            setError('');
            handleAddTask(title,subtitle,description,formattedStartDateTime,formattedEndDateTime)
            handleCloseModal()
        }
    };

    const checkFormValidity = () => {
        if (
            startDate &&
            startTime &&
            endDate &&
            endTime &&
            title.trim() !== '' 
            // &&
            // subtitle.trim() !== '' &&
            // description.trim() !== ''
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    useEffect(() => {
        checkFormValidity();
    }, [startDate, startTime, endDate, endTime, title, subtitle, description]);

    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal} position={'middle'}>
            <div style={{ marginTop: '15px' }}>
                {getTranslation('startDate')}
                <DateTimePicker
                    selectedDate={startDate}
                    setSelectedDate={setStartDate}
                    selectedTime={startTime}
                    setSelectedTime={setStartTime}
                />
            </div>
            <div>
                {getTranslation('endDate')}
                <DateTimePicker
                    selectedDate={endDate}
                    setSelectedDate={setEndDate}
                    selectedTime={endTime}
                    setSelectedTime={setEndTime}
                />
            </div>

            <div className='card' style={{ gap: '10px', flexDirection: 'column', display: 'flex' }}>
                {/* Title input */}
                <div>
                    <label>{getTranslation('title')}</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={getTranslation('enterTitle')}
                    />
                </div>

                {/* Subtitle input */}
                <div>
                    <label>{getTranslation('subtitle')}</label>
                    <input
                        type="text"
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                        placeholder={getTranslation('enterSubtitle')}
                    />
                </div>

                {/* Description input */}
                <div>
                    <label>{getTranslation('description')}</label>
                    <input
                        type='text'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={getTranslation('enterDescription')}
                    />
                </div>

            </div>

            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '15px' }}>
                {error && <div style={{ color: '#aa2f26', fontSize: '14px' }}>{error}</div>}
            </div>

            <div style={{ width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', gap: '15px', alignItems: 'center', marginTop: '15px' }}>
                <button onClick={handleCloseModal}>{getTranslation('cancel')}</button>
                <button disabled={!isFormValid} onClick={handleValidation}>
                    {getTranslation('add')}
                </button>
            </div>



        </Modal>
    );
}
