// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.input-container {
  margin-bottom: 10px;
  margin-top: 10px;
}

.tabs {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.tab {
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  margin-top: 10px;
  color: var(--button-text-color);
  background-color: var(--button-bg-color);
}

.tab.active {
  border-bottom-color: var(--button-underline-color);
}

.tab-content {
  margin-top: 20px;
}

.main-page {
  height: 97vh;
  max-height: 97vh;
  overflow-y: auto;
  max-width: 100%;
  width: 100%;
  padding-top: 2vh;
  padding-inline: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/MainPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,oCAAoC;EACpC,gBAAgB;EAChB,gBAAgB;EAChB,+BAA+B;EAC/B,wCAAwC;AAC1C;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin-top: 10px;\r\n}\r\n\r\n.input-container {\r\n  margin-bottom: 10px;\r\n  margin-top: 10px;\r\n}\r\n\r\n.tabs {\r\n  display: flex;\r\n  gap: 10px;\r\n  flex-direction: row;\r\n}\r\n\r\n.tab {\r\n  padding: 10px;\r\n  border: none;\r\n  background: none;\r\n  cursor: pointer;\r\n  border-bottom: 2px solid transparent;\r\n  border-radius: 0;\r\n  margin-top: 10px;\r\n  color: var(--button-text-color);\r\n  background-color: var(--button-bg-color);\r\n}\r\n\r\n.tab.active {\r\n  border-bottom-color: var(--button-underline-color);\r\n}\r\n\r\n.tab-content {\r\n  margin-top: 20px;\r\n}\r\n\r\n.main-page {\r\n  height: 97vh;\r\n  max-height: 97vh;\r\n  overflow-y: auto;\r\n  max-width: 100%;\r\n  width: 100%;\r\n  padding-top: 2vh;\r\n  padding-inline: 25px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
