import axios from "axios";
import { API_URL } from "./index";

// eslint-disable-next-line
export default {
  GetToken() {
    return axios({
      method: "post",
      url: `${API_URL}get-token`,
      data: {
        key: "PvCD5HzBzqJYfd0iHIpSLuoWESoQqb",
        secret: "ntscjBz7H4S2rnmIyjWy3nnSyPhi13",
      },
    });
  },
  UserRegister(
    token,
    name,
    lastname,
    username,
    password,
    phone,
    countryCode,
    email
  ) {
    return axios({
      method: "post",
      url: `${API_URL}flow/user-registration`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        name: name,
        lastname: lastname,
        username: username,
        password: password,
        phone: phone,
        countryCode: countryCode,
        email: email,
      },
    });
  },
  UserLogin(token, credential, password) {
    return axios({
      method: "post",
      url: `${API_URL}flow/user-login`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        credential: credential,
        password: password,
      },
    });
  },
  UpdateUser(token, userId, name, lastname, email, phone, countryCode) {
    const updateData = {};

    if (name !== undefined) updateData.name = name;
    if (lastname !== undefined) updateData.lastname = lastname;
    if (email !== undefined) updateData.email = email;
    if (phone !== undefined) updateData.phone = phone;
    if (countryCode !== undefined) updateData.countryCode = countryCode;

    return axios({
      method: "post",
      url: `${API_URL}flow/update-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: userId,
        updateData: updateData,
      },
    });
  },

  ChangeTheme(token, userId, theme) {
    return axios({
      method: "post",
      url: `${API_URL}flow/update-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: userId,
        updateData: {
          theme: theme,
        },
      },
    });
  },

  UpdateUserPassword(token, userId, password) {
    return axios({
      method: "post",
      url: `${API_URL}flow/update-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: userId,
        updateData: {
          password: password,
        },
      },
    });
  },
  CreateCollection(token, title, userID, members, projectId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/collection-create`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        title: title,
        description: "Description",
        settings: "Settings",
        userId: userID,
        members: members,
        projectId: projectId,
      },
    });
  },
  UpdateCollection(token, collectionId, title) {
    return axios({
      method: "post",
      url: `${API_URL}flow/collection-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: collectionId,
        updateData: {
          title: title,
        },
      },
    });
  },
  DeleteCollection(token, collectionId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/collection-delete`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: collectionId,
      },
    });
  },

  CreateProject(token, title, members) {
    return axios({
      method: "post",
      url: `${API_URL}flow/project-create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        title: title,
        members: members,
      },
    });
  },

  DeleteProject(token, projectId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/project-delete`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: projectId,
      },
    });
  },

  GetProjects(token) {
    return axios({
      method: "post",
      url: `${API_URL}flow/projects-by-user-id`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        withDetails: true,
      },
    });
  },

  UpdateProject(token, projectId, title) {
    return axios({
      method: "post",
      url: `${API_URL}flow/project-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: projectId,
        updateData: {
          title: title,
        },
      },
    });
  },

  GetCollections(token, projectId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/collections-by-user-id`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        projectId: projectId,
      },
    });
  },
  CreateAPI(
    token,
    collectionId,
    title,
    method,
    url,
    authorization,
    headers,
    body,
    members
  ) {
    return axios({
      method: "post",
      url: `${API_URL}flow/api-create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        collectionId: collectionId,
        title: title,
        method: method,
        url: url,
        auth: authorization,
        headers: headers,
        body: body,
        members: members,
      },
    });
  },
  UpdateAPI(
    token,
    collectionId,
    apiId,
    title,
    method,
    url,
    authorization,
    headers,
    body
  ) {
    const updateData = {
      method: method,
      url: url,
      auth: authorization,
      headers: headers,
      body: body,
    };

    if (title !== null) {
      updateData.title = title;
    }

    return axios({
      method: "post",
      url: `${API_URL}flow/api-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        collectionId: collectionId,
        apiId: apiId,
        updateData: updateData,
      },
    });
  },

  DeleteAPI(token, apiId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/api-delete`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        apiId: apiId,
      },
    });
  },

  CreateDocumentation(token, collectionId, apiId, title, parameters) {
    return axios({
      method: "post",
      url: `${API_URL}flow/document-create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        collectionId: collectionId,
        apiId: apiId,
        title: title,
        parameters: parameters,
      },
    });
  },
  UpdateDocumentation(token, apiId, id, updateData) {
    return axios({
      method: "post",
      url: `${API_URL}flow/document-update`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        apiId: apiId,
        id: id,
        updateData: updateData,
      },
    });
  },
  GetDocumentationByApiID(token, apiId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/documents-by-api-id`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        apiId: apiId,
      },
    });
  },
  GetPanels(token, projectId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-panels-by-user-id`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        projectId: projectId,
      },
    });
  },
  CreatePanel(token, title, members, projectId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-panel-create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        title: title,
        members: members,
        projectId: projectId,
      },
    });
  },
  UpdatePanel(token, panelId, title) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-panel-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        panelId: panelId,
        updateData: {
          title: title,
        },
      },
    });
  },
  DeletePanel(token, panelId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-panel-delete`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        panelId: panelId,
      },
    });
  },
  CreateBoard(token, panelId, title, members, projectId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/board-create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        title: title,
        panelId: panelId,
        members: members,
        projectId: projectId
      },
    });
  },
  UpdateBoardTitle(token, boardId, title) {
    return axios({
      method: "post",
      url: `${API_URL}flow/board-update`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        boardId: boardId,
        updateData: {
          title: title,
        },
      },
    });
  },
  DeleteBoard(token, boardId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/board-delete`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        boardId: boardId,
      },
    });
  },
  CreateTask(
    token,
    title,
    subtitle,
    description,
    panelId,
    boardId,
    members,
    taskStart,
    taskEnd,
    projectId
  ) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        teamId: "",
        title: title,
        subTitle: subtitle,
        description: description,
        panelId: panelId,
        boardId: boardId,
        members: members,
        taskStart: taskStart,
        taskEnd: taskEnd,
        taskTodo: false,
        projectId: projectId
      },
    });
  },

  LeaveTask(token, taskId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-leave`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        taskId: taskId,
      },
    });
  },

  CreateTodo(token, taskId, todoList) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        taskId: taskId,
        updateData: {
          taskTodo: todoList,
        },
      },
    });
  },
  UpdateTodo(token, taskId, todoList) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        taskId: taskId,
        updateData: {
          taskTodo: todoList,
        },
      },
    });
  },
  ChangeTaskStatus(token, taskId, status) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-change-status`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        taskId: taskId,
        status: status,
      },
    });
  },
  UpdateTaskDates(token, taskId, startDate, endDate) {
    const updateData = {};

    if (startDate) updateData.taskStart = startDate;
    if (endDate) updateData.taskEnd = endDate;

    return axios({
      method: "post",
      url: `${API_URL}flow/task-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        taskId: taskId,
        updateData: updateData,
      },
    });
  },
  UpdateTaskDescription(token, taskId, description) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        taskId: taskId,
        updateData: {
          description: description,
        },
      },
    });
  },
  DeleteTask(token, taskId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/task-delete`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        taskId: taskId,
      },
    });
  },

  KronosCreateAndStart(token, projectId, title, members) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-create-and-start`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        projectId,
        title,
        members,
      },
    });
  },

  KronosCreate(token, title, label, ownerId, userId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-create`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        title,
        label,
        ownerId,
        userId,
      },
    });
  },

  KronosCreateManual(token, title, projectId, members, startDate, endDate) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-manuel-create`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        title,
        projectId,
        members,
        kronosStartDate: startDate,
        kronosEndDate: endDate,
      },
    });
  },

  KronosDelete(token, kronosIds) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-delete`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        kronosIds,
      },
    });
  },

  KronosDiscard(token, kronosId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-discard`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        kronosId,
      },
    });
  },

  KronosUpdate(token, jobId, updateData) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-update`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        jobId,
        updateData,
      },
    });
  },

  KronosChangeStatus(token, jobId, userId, status) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-change-status`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        userId,
        jobId,
        status,
      },
    });
  },

  GetKronosByToken(token, projectId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-by-user-id`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        projectId,
      },
    });
  },

  GetKronosByOwnerId(token, userId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-by-owner-id`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        userId,
      },
    });
  },

  GetKronosByJobId(token, userId, jobId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-by-job-id`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        userId,
        jobId,
      },
    });
  },

  GetKronosByTaskId(token, userId, taskId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-by-task-id`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        userId,
        taskId,
      },
    });
  },

  KronosStart(token, userId, jobId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-start`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        userId,
        jobId,
      },
    });
  },

  KronosStop(token, logId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-stop`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        logId,
      },
    });
  },

  KronosCalculate(token, userId, jobId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-calculate`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        userId,
        jobId,
      },
    });
  },

  KronosSession(token, userId, jobId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-session`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        userId,
        jobId,
      },
    });
  },

  GetCurrentKronos(token) {
    return axios({
      method: "post",
      url: `${API_URL}flow/kronos-current-log`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  },

  SetUserSelectedProjectId(token, userId, selectedProjectId) {
    return axios({
      method: "post",
      url: `${API_URL}flow/update-user`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        id: userId,
        updateData: {
          selectedProject: selectedProjectId,
        },
      },
    });
  },
};
