import React, { useContext, useState, useEffect } from "react";
import "./Layout.css";

import { BsCollection, BsCalendar2Range } from "react-icons/bs";
import { GiSandsOfTime } from "react-icons/gi";
import { FaBookOpen } from "react-icons/fa";
import { BiSun } from "react-icons/bi";
import {
  IoAddOutline,
  IoEllipsisVerticalOutline,
  IoMoonOutline,
} from "react-icons/io5";

import { ThemeContext } from "../ThemeProvider";
import { GeneralContext } from "../GeneralProvider";
import { useLanguage } from "../LanguageProvider";
import { useNavigate } from "react-router-dom";

import MainPage from "../pages/MainPage/MainPage";
import LanguageSelect from "../components/LanguageSelect";
import Bust from "../components/Bust/Bust";
import MessageBox from "../components/MessageBox/MessageBox";
import AddBoardModal from "./Views/AddBoardModal";

function Layout() {
  const navigate = useNavigate();

  const { darkMode, toggleTheme } = useContext(ThemeContext);
  const {
    handlePageChange,
    currentPage,
    handleUpdateCollectionTitle,
    handleSelectApi,
    selectedApi,
    handleAddNewApi,
    collections,
    handleAddNewCollection,
    handleDeleteCollection,
    handleProfileSectionChange,
    panels,
    selectedBoard,
    handleAddNewPanel,
    handleUpdatePanelTitle,
    handleSelectBoard,
    handleAddNewBoard,
    handleDeletePanel,
    userId,
  } = useContext(GeneralContext);
  const { getTranslation } = useLanguage();

  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const [newCollectionTitle, setNewCollectionTitle] = useState("");
  const [isAddCollectionModalOpen, setIsAddCollectionModalOpen] =
    useState(false);
  const [modalStyle, setModalStyle] = useState({});
  const [isRenameMode, setIsRenameMode] = useState(false);
  const [renamingCollectionId, setRenamingCollectionId] = useState(null);
  const [renamedCollectionTitle, setRenamedCollectionTitle] = useState("");
  const [showDeleteCollectionModal, setShowDeleteCollectionModal] =
    useState(false);
  const [selectedCollectionToDelete, setSelectedCollectionToDelete] =
    useState("");
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [collapsedCollections, setCollapsedCollections] = useState(() => {
    const savedState =
      JSON.parse(localStorage.getItem("collapsedCollections")) || {};
    return savedState;
  });

  const [newPanelTitle, setNewPanelTitle] = useState("");
  const [isAddPanelModalOpen, setIsAddPanelModalOpen] = useState(false);
  const [collapsedPanels, setCollapsedPanels] = useState(() => {
    const savedState =
      JSON.parse(localStorage.getItem("collapsedPanels")) || {};
    return savedState;
  });
  const [renamingPanelId, setRenamingPanelId] = useState(null);
  const [renamedPanelTitle, setRenamedPanelTitle] = useState("");

  const [addBoardModalOpen, setAddBoardModalOpen] = useState(false);
  const [selectedPanelId, setSelectedPanelId] = useState("");

  const [showDeletePanelModal, setShowDeletePanelModal] = useState(false);
  const [selectedPanelToDelete, setSelectedPanelToDelete] = useState("");

  const togglePanelCollapse = (id) => {
    setCollapsedPanels((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const createNewPanel = () => {
    if (newPanelTitle.trim() === "") return;
    handleAddNewPanel(newPanelTitle);
    setNewPanelTitle("");
    setIsAddPanelModalOpen(false);
  };

  const handleRenamePanel = (panelId, newName) => {
    if (newName) {
      handleUpdatePanelTitle(panelId, newName);
      setRenamingPanelId(null);
    }
  };

  const enablePanelRenameMode = (panel) => {
    setRenamingPanelId(panel.panelId);
    setRenamedPanelTitle(panel.panelTitle);
    setIsMenuOpen(null);
  };

  useEffect(() => {
    localStorage.setItem("collapsedPanels", JSON.stringify(collapsedPanels));
  }, [collapsedPanels]);

  const handleLogout = () => {
    handlePageChange("collections");
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    navigate("/");
  };

  useEffect(() => {
    if (collections && collections.length > 0) {
      const updatedCollapsedState = collections.reduce((acc, collection) => {
        acc[collection.collectionId] =
          collapsedCollections[collection.collectionId] !== undefined
            ? collapsedCollections[collection.collectionId]
            : true;
        return acc;
      }, {});
      setCollapsedCollections(updatedCollapsedState);
    }
    // eslint-disable-next-line
  }, [collections]);

  useEffect(() => {
    localStorage.setItem(
      "collapsedCollections",
      JSON.stringify(collapsedCollections)
    );
  }, [collapsedCollections]);

  const handleRenameCollection = (collectionId, newName) => {
    if (newName) {
      handleUpdateCollectionTitle(collectionId, newName);
      setIsRenameMode(false);
      setRenamingCollectionId(null);
      setIsMenuOpen(null);
    }
  };

  const enableRenameMode = (collection) => {
    setRenamingCollectionId(collection.collectionId);
    setRenamedCollectionTitle(collection.collectionTitle);
    setIsRenameMode(true);
    setIsMenuOpen(null);
  };

  const toggleCollapse = (id) => {
    if (!isRenameMode) {
      setCollapsedCollections((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    }
  };

  const getOptionStyle = (option) => {
    switch (option) {
      case "GET":
        return {
          color: "#3fc462",
          fontWeight: "600",
          marginRight: "5px",
          fontSize: "10px",
          width: "40px",
          justifyContent: "flex-start",
          display: "flex",
        };
      case "POST":
        return {
          color: "#d9db40",
          fontWeight: "600",
          marginRight: "5px",
          fontSize: "10px",
          width: "40px",
          justifyContent: "flex-start",
          display: "flex",
        };
      case "PUT":
        return {
          color: "#3c6ad6",
          fontWeight: "600",
          marginRight: "5px",
          fontSize: "10px",
          width: "40px",
          justifyContent: "flex-start",
          display: "flex",
        };
      case "DELETE":
        return {
          color: "#e3a6b4",
          fontWeight: "600",
          marginRight: "5px",
          fontSize: "10px",
          width: "40px",
          justifyContent: "flex-start",
          display: "flex",
        };
      default:
        return {};
    }
  };

  const handleMenuToggle = (event, collectionId) => {
    const windowHeight = window.innerHeight;
    const modalHeight = 100;
    const collectionRect = event.currentTarget.getBoundingClientRect();

    const bottomSpace = windowHeight - collectionRect.bottom;

    if (bottomSpace < modalHeight) {
      setModalStyle({
        top: collectionRect.top - modalHeight + window.scrollY + "px",
        left: collectionRect.left + "px",
      });
    } else {
      setModalStyle({
        top: collectionRect.bottom + window.scrollY + "px",
        left: collectionRect.left + "px",
      });
    }

    setIsMenuOpen(isMenuOpen === collectionId ? null : collectionId);
  };

  const createNewCollection = () => {
    if (newCollectionTitle.trim() === "") return;
    handleAddNewCollection(newCollectionTitle);
    setNewCollectionTitle("");
    setIsAddCollectionModalOpen(false);
  };

  const handleAddApi = (collection) => {
    handleAddNewApi(collection.collectionId);
    setIsMenuOpen(null);
  };

  const handleAddBoard = (panel) => {
    setSelectedPanelId(panel.panelId);
    handleAddNewBoard(panel.panelId);
    setIsMenuOpen(null);
    setAddBoardModalOpen(true);
  };

  const handleClickOutside = (event) => {
    const modal = document.querySelector(".modal");
    const button = event.target.closest(".menu-button");

    if (!modal || modal.contains(event.target) || button) {
      return;
    }

    if (isMenuOpen) {
      setIsMenuOpen(null);
    }
  };

  const handleDeleteClick = (collectionId) => {
    setIsMenuOpen(null);
    setSelectedCollectionToDelete(collectionId);
    setShowDeleteCollectionModal(true);
  };

  const handleAcceptDelete = () => {
    handleDeleteCollection(selectedCollectionToDelete);
    setSelectedCollectionToDelete("");
    setShowDeleteCollectionModal(false);
  };

  const handleDeclineDelete = () => {
    setSelectedCollectionToDelete("");
    setShowDeleteCollectionModal(false);
  };

  const handleDeletePanelClick = (panelId) => {
    setIsMenuOpen(null);
    setSelectedPanelToDelete(panelId);
    setShowDeletePanelModal(true);
  };

  const handleAcceptPanelDelete = () => {
    handleDeletePanel(selectedPanelToDelete);
    setSelectedPanelToDelete("");
    setShowDeletePanelModal(false);
  };

  const handleDeclinePanelDelete = () => {
    setSelectedPanelToDelete("");
    setShowDeletePanelModal(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isMenuOpen]);

  return (
    <div className="layout">
      <div
        style={{
          width: "380px",
          flexDirection: "row",
          display: "flex",
          height: "100%",
        }}
      >
        <aside className="sidebar">
          <nav>
            <ul>
              <li>
                <button
                  className={
                    currentPage === "collections" || currentPage === "apis"
                      ? "active"
                      : ""
                  }
                  onClick={() => handlePageChange("collections")}
                >
                  <BsCollection /> <p>{getTranslation("collections")}</p>
                </button>
              </li>
              <li>
                <button
                  className={currentPage === "documentation" ? "active" : ""}
                  onClick={() => handlePageChange("documentation")}
                >
                  <FaBookOpen />
                  <p>{getTranslation("documentation")}</p>
                </button>
              </li>
              {/* <li>
                <button className={currentPage === 'testautomation' ? 'active' : ''} onClick={() => handlePageChange('testautomation')}><GrTestDesktop /><p>{getTranslation('testAutomation')}</p></button>
              </li> */}
              <li>
                <button
                  className={currentPage === "task-management" ? "active" : ""}
                  onClick={() => handlePageChange("task-management")}
                >
                  <BsCalendar2Range />
                  <p>{getTranslation("taskManagement")}</p>
                </button>
              </li>
              <li>
                <button
                  className={currentPage === "kronos" ? "active" : ""}
                  onClick={() => handlePageChange("kronos")}
                >
                  <GiSandsOfTime />
                  <p>Kronos</p>
                </button>
              </li>
            </ul>
            <div
              className="profileButton"
              onClick={() => handlePageChange("profile")}
            >
              <Bust />
            </div>
            <div className="language-toggle">
              <LanguageSelect />
            </div>
            <div className="theme-toggle">
              <button onClick={toggleTheme}>
                {darkMode ? <BiSun /> : <IoMoonOutline />}
              </button>
            </div>
          </nav>
        </aside>

        <div className="content">
          {currentPage === "profile" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "20px",
                  flexDirection: "column",
                }}
              >
                <p className="title">{getTranslation("profileSettings")}</p>
                <div className="profile-options">
                  <span
                    onClick={() => handleProfileSectionChange("profileInfo")}
                  >
                    {getTranslation("profileInfo")}
                  </span>
                  <span
                    onClick={() => handleProfileSectionChange("changePassword")}
                  >
                    {getTranslation("changePassword")}
                  </span>
                  {/* <span onClick={() => handleProfileSectionChange('suspendAccount')}>{getTranslation('suspendAccount')}</span> */}
                  <span onClick={() => setShowLogoutModal(true)}>
                    {getTranslation("logout")}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              {currentPage === "task-management" ? (
                <>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                    }}
                  >
                    <p className="title">{getTranslation("panels")}</p>
                    <IoAddOutline
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsAddPanelModalOpen(true)}
                      size={24}
                    />
                  </div>
                  {isAddPanelModalOpen && (
                    <div className="modal">
                      <h3>{getTranslation("addNewPanel")}</h3>
                      <input
                        type="text"
                        value={newPanelTitle}
                        onChange={(e) => setNewPanelTitle(e.target.value)}
                        placeholder={getTranslation("panelTitle")}
                        maxLength={25}
                      />
                      <div
                        style={{
                          width: "350px",
                          flexDirection: "row",
                          marginTop: "15px",
                          display: "flex",
                          gap: "15px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button onClick={() => setIsAddPanelModalOpen(false)}>
                          {getTranslation("cancel")}
                        </button>
                        <button onClick={createNewPanel}>
                          {getTranslation("add")}
                        </button>
                      </div>
                    </div>
                  )}

                  <div>
                    {panels.map((panel, panelIndex) => {
                      const isCollapsed = collapsedPanels[panel.panelId];
                      return (
                        <div key={panelIndex} style={{ paddingInline: "15px" }}>
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              onClick={() => togglePanelCollapse(panel.panelId)}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {renamingPanelId === panel.panelId ? (
                                <input
                                  type="text"
                                  value={renamedPanelTitle}
                                  onChange={(e) =>
                                    setRenamedPanelTitle(e.target.value)
                                  }
                                  onBlur={() =>
                                    handleRenamePanel(
                                      panel.panelId,
                                      renamedPanelTitle
                                    )
                                  }
                                  onKeyPress={(e) =>
                                    e.key === "Enter" &&
                                    handleRenamePanel(
                                      panel.panelId,
                                      renamedPanelTitle
                                    )
                                  }
                                  autoFocus
                                  maxLength={25}
                                />
                              ) : (
                                <p className="panel-title">
                                  {panel.panelTitle}
                                </p>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>{isCollapsed ? "+" : "-"}</span>
                              </div>
                            </div>
                            <IoEllipsisVerticalOutline
                              className="menu-button"
                              onClick={(e) =>
                                handleMenuToggle(e, panel.panelId)
                              }
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                              size={20}
                            />
                            {isMenuOpen === panel.panelId && (
                              <div className="modal" style={modalStyle}>
                                <button
                                  className="modal-button"
                                  onClick={() => handleAddBoard(panel)}
                                >
                                  {getTranslation("addNewBoard")}
                                </button>
                                {panel.panelCreatedBy === userId && (
                                  <button
                                    className="modal-button"
                                    onClick={() => enablePanelRenameMode(panel)}
                                  >
                                    {getTranslation("rename")}
                                  </button>
                                )}
                                {panel.panelCreatedBy === userId && (
                                  <button
                                    className="modal-button"
                                    onClick={() =>
                                      handleDeletePanelClick(panel.panelId)
                                    }
                                  >
                                    {getTranslation("delete")}
                                  </button>
                                )}
                              </div>
                            )}
                          </div>

                          {!isCollapsed && (
                            <div>
                              {panel.boards.length > 0 ? (
                                panel.boards.map((board, index) => {
                                  return (
                                    <button
                                      key={index}
                                      onClick={() =>
                                        handleSelectBoard(board, panel.panelId)
                                      }
                                      className="board-item"
                                      style={{
                                        backgroundColor:
                                          board.boardId ===
                                            selectedBoard?.boardId &&
                                          "var(--button-bg-color)",
                                        color:
                                          board.boardId ===
                                            selectedBoard?.boardId && "#fff",
                                      }}
                                    >
                                      <p style={{ textAlign: "left" }}>
                                        {board.boardTitle}
                                      </p>
                                    </button>
                                  );
                                })
                              ) : (
                                <p style={{ fontSize: "12px" }}>
                                  {getTranslation("noBoardAvailable")}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingRight: "20px",
                    }}
                  >
                    <p className="title">{getTranslation("collections")}</p>
                    <IoAddOutline
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsAddCollectionModalOpen(true)}
                      size={24}
                    />
                  </div>
                  {isAddCollectionModalOpen && (
                    <div className="modal">
                      <h3>{getTranslation("addNewCollection")}</h3>
                      <input
                        type="text"
                        value={newCollectionTitle}
                        onChange={(e) => setNewCollectionTitle(e.target.value)}
                        placeholder={getTranslation("collectionTitle")}
                        maxLength={25}
                      />
                      <div
                        style={{
                          width: "350px",
                          flexDirection: "row",
                          marginTop: "15px",
                          display: "flex",
                          gap: "15px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          onClick={() => setIsAddCollectionModalOpen(false)}
                        >
                          {getTranslation("cancel")}
                        </button>
                        <button onClick={createNewCollection}>
                          {getTranslation("add")}
                        </button>
                      </div>
                    </div>
                  )}

                  <div>
                    {collections.map((collection, collectionIndex) => {
                      const isCollapsed =
                        collapsedCollections[collection.collectionId];
                      return (
                        <div
                          key={collectionIndex}
                          style={{ paddingInline: "15px" }}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              onClick={() =>
                                toggleCollapse(collection.collectionId)
                              }
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              {isRenameMode &&
                              renamingCollectionId ===
                                collection.collectionId ? (
                                <input
                                  type="text"
                                  value={renamedCollectionTitle}
                                  onChange={(e) =>
                                    setRenamedCollectionTitle(e.target.value)
                                  }
                                  onBlur={() =>
                                    handleRenameCollection(
                                      collection.collectionId,
                                      renamedCollectionTitle
                                    )
                                  } // Kaydet
                                  onKeyPress={(e) =>
                                    e.key === "Enter" &&
                                    handleRenameCollection(
                                      collection.collectionId,
                                      renamedCollectionTitle
                                    )
                                  } // Enter ile kaydet
                                  autoFocus
                                  maxLength={25}
                                />
                              ) : (
                                <p className="collection-title">
                                  {collection.collectionTitle}
                                </p>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>{isCollapsed ? "+" : "-"}</span>
                              </div>
                            </div>
                            <IoEllipsisVerticalOutline
                              className="menu-button"
                              onClick={(e) =>
                                handleMenuToggle(e, collection.collectionId)
                              }
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                              size={20}
                            />
                          </div>

                          {isMenuOpen === collection.collectionId && (
                            <div className="modal" style={modalStyle}>
                              <button
                                className="modal-button"
                                onClick={() => handleAddApi(collection)}
                              >
                                {getTranslation("addNewApi")}
                              </button>
                              <button
                                className="modal-button"
                                onClick={() => enableRenameMode(collection)}
                              >
                                {getTranslation("rename")}
                              </button>
                              <button
                                className="modal-button"
                                onClick={() =>
                                  handleDeleteClick(collection.collectionId)
                                }
                              >
                                {getTranslation("delete")}
                              </button>
                            </div>
                          )}

                          {!isCollapsed && (
                            <div>
                              {collection.apis.length > 0 ? (
                                collection.apis.map((api, index) => {
                                  return (
                                    <button
                                      key={index}
                                      onClick={() =>
                                        handleSelectApi(
                                          api,
                                          collection.collectionId
                                        )
                                      }
                                      className="api-item"
                                      style={{
                                        backgroundColor:
                                          api.apiId === selectedApi.apiId &&
                                          "var(--button-bg-color)",
                                        color:
                                          api.apiId === selectedApi.apiId &&
                                          "#fff",
                                      }}
                                    >
                                      <p style={getOptionStyle(api.method)}>
                                        {api.method}
                                      </p>
                                      <p style={{ textAlign: "left" }}>
                                        {api.apiTitle}
                                      </p>
                                    </button>
                                  );
                                })
                              ) : (
                                <p style={{ fontSize: "12px" }}>
                                  {getTranslation("noApiAvailable")}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <MessageBox
        show={showDeleteCollectionModal}
        title={getTranslation("areYouSure")}
        message={getTranslation("collectionDeleteWarning")}
        onConfirm={handleAcceptDelete}
        onClose={handleDeclineDelete}
      />
      <MessageBox
        show={showDeletePanelModal}
        title={getTranslation("areYouSure")}
        message={getTranslation("panelDeleteWarning")}
        onConfirm={handleAcceptPanelDelete}
        onClose={handleDeclinePanelDelete}
      />
      <MessageBox
        show={showLogoutModal}
        title={getTranslation("areYouSure")}
        message={getTranslation("confirmLogout")}
        onConfirm={handleLogout}
        onClose={() => setShowLogoutModal(false)}
      />

      <AddBoardModal
        isOpen={addBoardModalOpen}
        onClose={() => setAddBoardModalOpen(false)}
        panelId={selectedPanelId}
      />
      <div className="main-page-container">
        <MainPage />
      </div>

      <div style={{ width: "1%" }} />
    </div>
  );
}

export default Layout;
