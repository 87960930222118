// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.params-container {
  display: grid;
  gap: 15px;
}

.param-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.param-input {
  padding: 8px;
  margin-right: 10px;
}

.param-item-row label {
  margin-left: 5px;
}

.delete-button {
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}


@media (max-width: 1655px) {
  .params-container {
    grid-template-columns: repeat(1, 1fr);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/DocumentationPage/DocumentationPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;;AAGA;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":[".params-container {\r\n  display: grid;\r\n  gap: 15px;\r\n}\r\n\r\n.param-item-row {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.param-input {\r\n  padding: 8px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.param-item-row label {\r\n  margin-left: 5px;\r\n}\r\n\r\n.delete-button {\r\n  color: white;\r\n  border: none;\r\n  padding: 5px 10px;\r\n  text-align: center;\r\n  text-decoration: none;\r\n  display: inline-block;\r\n  font-size: 14px;\r\n  cursor: pointer;\r\n  margin-left: 10px;\r\n}\r\n\r\n\r\n@media (max-width: 1655px) {\r\n  .params-container {\r\n    grid-template-columns: repeat(1, 1fr);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
