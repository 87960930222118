import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../App.css";

const RichTextEditor = ({ editorState, setEditorState }) => {

    const onEditorStateChange = (newState) => {
        setEditorState(newState);
    };

    return (
        <div className="editor-container" style={{ maxWidth: '100%', overflowY: 'auto' }}>
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'strikethrough']
                    },
                    blockType: {
                        inDropdown: true,
                    },
                    fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 36],
                    },
                    fontFamily: {
                        options: ['Arial', 'Georgia', 'Times New Roman', 'Verdana'],
                    },
                }}
                toolbarClassName="toolbar"
                wrapperClassName="wrapper"
                editorClassName="editor"
            />
        </div>
    );
};

export default RichTextEditor;
