// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-phone-select {
    position: relative;
    width: 100%;
    background-color: #eee !important;
    border: 1px solid #eee !important;
    border-radius: 4px;
    margin: 8px 0;
  }
  
  .select-trigger {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #8F9198;
    height: 27px;
    font-family: "Montserrat", sans-serif;
  }
  
  .options-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #eee !important;
    background-color: #eee !important;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 4px;
  }
  
  .option-item {
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #8F9198;
    font-family: "Montserrat", sans-serif;
  }
  
  .option-item:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .flag-image {
    width: 20px;
    height: 15px;
    margin-right: 8px;
  }`, "",{"version":3,"sources":["webpack://./src/pages/AuthPage/SignUpPhoneSelect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,iCAAiC;IACjC,iCAAiC;IACjC,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,qCAAqC;EACvC;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,iCAAiC;IACjC,iCAAiC;IACjC,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,qCAAqC;EACvC;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB","sourcesContent":[".custom-phone-select {\n    position: relative;\n    width: 100%;\n    background-color: #eee !important;\n    border: 1px solid #eee !important;\n    border-radius: 4px;\n    margin: 8px 0;\n  }\n  \n  .select-trigger {\n    padding: 8px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    color: #8F9198;\n    height: 27px;\n    font-family: \"Montserrat\", sans-serif;\n  }\n  \n  .options-container {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    border: 1px solid #eee !important;\n    background-color: #eee !important;\n    z-index: 1000;\n    max-height: 200px;\n    overflow-y: auto;\n    border-radius: 4px;\n  }\n  \n  .option-item {\n    padding: 8px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    color: #8F9198;\n    font-family: \"Montserrat\", sans-serif;\n  }\n  \n  .option-item:hover {\n    background-color: rgba(0, 0, 0, 0.1);\n  }\n  \n  .flag-image {\n    width: 20px;\n    height: 15px;\n    margin-right: 8px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
