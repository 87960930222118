import React, { useState, useEffect } from "react";
import Timer from "./Views/Timer";
import TimerList from "./Views/TimerList";
import "./Kronos.css";

export default function Kronos() {
  const [timeLog, setTimeLog] = useState([
    {
      id: "1",
      title: "Task A",
      start: new Date(2024, 10, 6, 9, 0),
      end: new Date(2024, 10, 6, 10, 0),
      duration: 3600,
      groupId: "grp1",
    },
    {
      id: "2",
      title: "Task B",
      start: new Date(2024, 10, 5, 14, 30),
      end: new Date(2024, 10, 5, 15, 0),
      duration: 1800,
      groupId: "grp2",
    },
    {
      id: "3",
      title: "Task C",
      start: new Date(),
      end: new Date(),
      duration: 5400,
      groupId: "grp3",
    },
    {
      id: "4",
      title: "Task D",
      start: new Date(2024, 10, 4, 12, 0),
      end: new Date(2024, 10, 4, 13, 30),
      duration: 5400,
      groupId: "grp4",
    },
  ]);
  const [currentLog, setCurrentLog] = useState(null); // Timer için güncel log bilgisi

  useEffect(() => {
    console.log("timeLog: ", timeLog);
  }, [timeLog]);

  const handleAddLog = (log) => {
    setTimeLog((prevLogs) => [...prevLogs, log]);
  };

  const handleDeleteLog = (id) => {
    setTimeLog(timeLog.filter((log) => log.id !== id));
  };

  const handleUpdateLog = (index, field, newValue) => {
    const updatedLog = [...timeLog];
    const log = updatedLog[index];

    if (field === "start") {
      log.start = new Date(newValue);
      if (log.end) {
        log.duration = calculateDuration(log.start, log.end);
      }
    } else if (field === "end") {
      log.end = new Date(newValue);
      if (log.start) {
        log.duration = calculateDuration(log.start, log.end);
      }
    } else if (field === "duration") {
      const newDuration = parseInt(newValue, 10);
      log.duration = newDuration;
    } else {
      log[field] = newValue;
    }

    setTimeLog(updatedLog);
  };

  const calculateDuration = (start, end) => {
    const diffInSeconds = Math.floor((end - start) / 1000);
    return diffInSeconds;
  };

  const handlePlayLog = (log) => {
    setCurrentLog(log); // Timer bileşenine bilgileri gönder
    console.log("log: ", log);
  };

  return (
    <div className="main-page">
      <Timer
        onAddLog={handleAddLog}
        timeLog={timeLog}
        setTimeLog={setTimeLog}
        currentLog={currentLog} // Timer bileşenine gönder
      />
      <TimerList
        timeLog={timeLog}
        handleDelete={handleDeleteLog}
        handleUpdateLog={handleUpdateLog}
        onPlay={handlePlayLog} // TimerList'ten log'u al
      />
    </div>
  );
}
