import React from 'react'
import { useLanguage } from '../LanguageProvider';

export default function LanguageSelect({ isThemeSensitive }) {

    const { language, changeLanguage } = useLanguage();

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value);
    };

    if (!isThemeSensitive) {
        return (
            <div className='custom-select'>
                <select style={{ width: '100%', backgroundColor: '#2E2E2E', borderColor: '#2E2E2E', fontSize: '12px' }} value={language} onChange={handleLanguageChange}>
                    <option value="en">English</option>
                    <option value="tr">Türkçe</option>
                    <option value="de">Deutsch</option>
                    <option value="it">Italiano</option>
                    <option value="es">Español</option>
                    <option value="fr">Français</option>
                    {/* Add more languages as needed */}
                </select>
                <span className="arrow">&#9662;</span>
            </div>
        )
    }
    else {

        return (
            <div className='custom-select'>
                <select style={{ width: '100%' }} value={language} onChange={handleLanguageChange}>
                    <option value="en">English</option>
                    <option value="tr">Türkçe</option>
                    <option value="de">Deutsch</option>
                    <option value="it">Italiano</option>
                    <option value="es">Español</option>
                    <option value="fr">Français</option>
                    {/* Add more languages as needed */}
                </select>
                <span className="arrow">&#9662;</span>
            </div>
        )
    }
}
