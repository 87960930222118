import axios from "axios";
import { API_URL } from "./index";

// eslint-disable-next-line
export default
    {
        GetToken() {
            return axios({
                method: 'post',
                url: `${API_URL}get-token`,
                data: {
                    "key": "PvCD5HzBzqJYfd0iHIpSLuoWESoQqb",
                    "secret": "ntscjBz7H4S2rnmIyjWy3nnSyPhi13"
                }
            });
        },
        UserRegister(token, name, lastname, username, password, phone, countryCode, email) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/user-registration`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    name: name,
                    lastname: lastname,
                    username: username,
                    password: password,
                    phone: phone,
                    countryCode: countryCode,
                    email: email
                }
            });
        },
        UserLogin(token, credential, password) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/user-login`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    credential: credential,
                    password: password,
                }
            });
        },
        UpdateUser(token, userId, name, lastname, email, phone, countryCode) {
            const updateData = {};

            if (name !== undefined) updateData.name = name;
            if (lastname !== undefined) updateData.lastname = lastname;
            if (email !== undefined) updateData.email = email;
            if (phone !== undefined) updateData.phone = phone;
            if (countryCode !== undefined) updateData.countryCode = countryCode;

            return axios({
                method: 'post',
                url: `${API_URL}flow/update-user`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    id: userId,
                    updateData: updateData
                }
            });
        },
        UpdateUserPassword(token, userId, password) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/update-user`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    id: userId,
                    updateData: {
                        password: password
                    }
                }
            });
        },
        CreateCollection(token, title, userID) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/collection-create`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    title: title,
                    description: "Description",
                    settings: "Settings",
                    userId: userID
                }
            });
        },
        UpdateCollection(token, collectionId, title) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/collection-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    id: collectionId,
                    updateData: {
                        title: title
                    }
                }
            });
        },
        DeleteCollection(token, collectionId) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/collection-delete`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    id: collectionId
                }
            });
        },
        GetCollections(token) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/collections-by-user-id`,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        },
        CreateAPI(token, collectionId, title, method, url, authorization, headers, body) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/api-create`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    collectionId: collectionId,
                    title: title,
                    method: method,
                    url: url,
                    auth: authorization,
                    headers: headers,
                    body: body
                }
            });
        },
        UpdateAPI(token, collectionId, apiId, title, method, url, authorization, headers, body) {
            const updateData = {
                method: method,
                url: url,
                auth: authorization,
                headers: headers,
                body: body,
            };

            if (title !== null) {
                updateData.title = title;
            }

            return axios({
                method: 'post',
                url: `${API_URL}flow/api-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    collectionId: collectionId,
                    apiId: apiId,
                    updateData: updateData
                }
            });
        },
        CreateDocumentation(token, collectionId, apiId, title, parameters) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/document-create`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    collectionId: collectionId,
                    apiId: apiId,
                    title: title,
                    parameters: parameters
                }
            });
        },
        GetDocumentationByApiID(token, apiId) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/documents-by-api-id`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    apiId: apiId
                }
            });
        },
        GetPanels(token) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-panels-by-user-id`,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        },
        CreatePanel(token, title, members) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-panel-create`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    title: title,
                    members: members
                }
            });
        },
        UpdatePanel(token, panelId, title) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-panel-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    panelId: panelId,
                    updateData: {
                        title: title
                    }
                }
            });
        },
        DeletePanel(token, panelId) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-panel-delete`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    panelId: panelId
                }
            });
        },
        CreateBoard(token, panelId, title, members) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/board-create`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    title: title,
                    panelId: panelId,
                    members: members
                }
            });
        },
        UpdateBoardTitle(token, boardId, title) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/board-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    boardId: boardId,
                    updateData: {
                        title: title
                    }
                }
            });
        },
        DeleteBoard(token, boardId) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/board-delete`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    boardId: boardId
                }
            });
        },
        CreateTask(token, title, subtitle, description, panelId, boardId, members, taskStart, taskEnd) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-create`,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    teamId: "",
                    title: title,
                    subTitle: subtitle,
                    description: description,
                    panelId: panelId,
                    boardId: boardId,
                    members: members,
                    taskStart: taskStart,
                    taskEnd: taskEnd,
                    taskTodo: false
                }
            });
        },
        CreateTodo(token, taskId, todoList) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    taskId: taskId,
                    updateData: {
                        taskTodo: todoList
                    }
                }
            });
        },
        UpdateTodo(token, taskId, todoList) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    taskId: taskId,
                    updateData: {
                        taskTodo: todoList
                    }
                }
            });
        },
        ChangeTaskStatus(token, taskId, status) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-change-status`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    taskId: taskId,
                    status: status
                }
            });
        },
        UpdateTaskDates(token, taskId, startDate, endDate) {
            const updateData = {};

            if (startDate) updateData.taskStart = startDate;
            if (endDate) updateData.taskEnd = endDate;

            return axios({
                method: 'post',
                url: `${API_URL}flow/task-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    taskId: taskId,
                    updateData: updateData,
                },
            });
        },
        UpdateTaskDescription(token, taskId, description) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-update`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    taskId: taskId,
                    updateData: {
                        description: description
                    }
                },
            });
        },
        DeleteTask(token, taskId) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/task-delete`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    taskId: taskId
                },
            });
        },
    }