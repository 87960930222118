// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select {
  position: relative;
  width: 95px;
  background-color: var(--input-bg-color);
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  margin-right: 10px;
}

.select-trigger {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #8F9198;
  height: 27px;
  font-family: "Montserrat", sans-serif;
}

.options-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #2E2E2E;
  background-color: #2E2E2E;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
}

.option-item {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #8F9198;
  font-family: "Montserrat", sans-serif;
}

.option-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.flag-image {
  width: 20px;
  height: 15px;
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomPhoneSelect/CustomPhoneSelect.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,uCAAuC;EACvC,2CAA2C;EAC3C,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,qCAAqC;AACvC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".custom-select {\r\n  position: relative;\r\n  width: 95px;\r\n  background-color: var(--input-bg-color);\r\n  border: 1px solid var(--input-border-color);\r\n  border-radius: 4px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.select-trigger {\r\n  padding: 8px;\r\n  cursor: pointer;\r\n  display: flex;\r\n  align-items: center;\r\n  color: #8F9198;\r\n  height: 27px;\r\n  font-family: \"Montserrat\", sans-serif;\r\n}\r\n\r\n.options-container {\r\n  position: absolute;\r\n  top: 100%;\r\n  left: 0;\r\n  right: 0;\r\n  border: 1px solid #2E2E2E;\r\n  background-color: #2E2E2E;\r\n  z-index: 1000;\r\n  max-height: 200px;\r\n  overflow-y: auto;\r\n  border-radius: 4px;\r\n}\r\n\r\n.option-item {\r\n  padding: 8px;\r\n  cursor: pointer;\r\n  display: flex;\r\n  align-items: center;\r\n  color: #8F9198;\r\n  font-family: \"Montserrat\", sans-serif;\r\n}\r\n\r\n.option-item:hover {\r\n  background-color: rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.flag-image {\r\n  width: 20px;\r\n  height: 15px;\r\n  margin-right: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
