// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DateRangeSelector.css */

.date-range-selector {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
}

.date-range-button {
  padding: 10px 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.date-range-button.selected {
  border-color: rgba(45, 194, 141, 1) !important;
  background-color: rgba(45, 194, 141, 0.1) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.date-range-button:hover {
  background-color: #f1f1f1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Kronos/Views/DateRangeSelector.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,wCAAwC;EACxC,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,8CAA8C;EAC9C,oDAAoD;EACpD,mDAAmD;AACrD;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* DateRangeSelector.css */\n\n.date-range-selector {\n  display: flex;\n  gap: 10px;\n  justify-content: center;\n  margin: 20px 0;\n}\n\n.date-range-button {\n  padding: 10px 20px;\n  border: 2px solid #ddd;\n  border-radius: 8px;\n  background-color: #f9f9f9;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n\n.date-range-button.selected {\n  border-color: rgba(45, 194, 141, 1) !important;\n  background-color: rgba(45, 194, 141, 0.1) !important;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;\n}\n\n.date-range-button:hover {\n  background-color: #f1f1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
