// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multipleMessageBox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.multipleMessageBox {
  background: var(--background-color);
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: flex-start;
}

.multipleMessageBox-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.multipleMessageBox-message {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
}

.multipleMessageBox-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.multipleMessageBox-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 200px;
}

.multipleMessageBox-button.cancel {
  background-color: #aa2f26;
  color: white;
}

.multipleMessageBox-button.warning {
  background-color: #dc8a0e;
  color: white;
}

.multipleMessageBox-button.confirm {
  background-color: #327434;
  color: white;
}

.multipleMessageBox-button:hover {
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/MultipleMessageBox/MultipleMessageBox.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,mCAAmC;EACnC,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,2CAA2C;EAC3C,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".multipleMessageBox-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.multipleMessageBox {\n  background: var(--background-color);\n  padding: 20px;\n  border-radius: 8px;\n  max-width: 500px;\n  width: 100%;\n  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);\n  text-align: flex-start;\n}\n\n.multipleMessageBox-title {\n  font-size: 20px;\n  margin-bottom: 10px;\n}\n\n.multipleMessageBox-message {\n  font-size: 13px;\n  line-height: 20px;\n  margin-bottom: 20px;\n}\n\n.multipleMessageBox-buttons {\n  display: flex;\n  justify-content: flex-end;\n  gap: 15px;\n}\n\n.multipleMessageBox-button {\n  padding: 10px 20px;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  width: 200px;\n}\n\n.multipleMessageBox-button.cancel {\n  background-color: #aa2f26;\n  color: white;\n}\n\n.multipleMessageBox-button.warning {\n  background-color: #dc8a0e;\n  color: white;\n}\n\n.multipleMessageBox-button.confirm {\n  background-color: #327434;\n  color: white;\n}\n\n.multipleMessageBox-button:hover {\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
