// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Toaster.css */
.toaster {
  position: fixed;
  padding-top: 15px;
  min-width: 300px;
  color: white;
  z-index: 999;
  animation: fadeIn 0.5s;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-direction: column;
  border-radius: 3px;
}

.toaster.success {
  background-color: #4caf50;
}

.toaster.error {
  background-color: #f44336;
}

.toaster.warning {
  background-color: #dccf0e;
}

.toaster-close {
  cursor: pointer;
  margin-left: 15px;
  color: white;
  font-size: 16px;
}

.toaster-progress {
  background-color: white;
  height: 4px;
  width: 100%;
  /* Başlangıç genişliği */
  transition: width 0.1s linear;
  /* Genişlik değişimi */
  margin-top: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Toaster/Toaster.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,8BAA8B;EAC9B,0BAA0B;EAC1B,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,WAAW;EACX,wBAAwB;EACxB,6BAA6B;EAC7B,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":["/* Toaster.css */\r\n.toaster {\r\n  position: fixed;\r\n  padding-top: 15px;\r\n  min-width: 300px;\r\n  color: white;\r\n  z-index: 999;\r\n  animation: fadeIn 0.5s;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: space-between;\r\n  flex-direction: column;\r\n  border-radius: 3px;\r\n}\r\n\r\n.toaster.success {\r\n  background-color: #4caf50;\r\n}\r\n\r\n.toaster.error {\r\n  background-color: #f44336;\r\n}\r\n\r\n.toaster.warning {\r\n  background-color: #dccf0e;\r\n}\r\n\r\n.toaster-close {\r\n  cursor: pointer;\r\n  margin-left: 15px;\r\n  color: white;\r\n  font-size: 16px;\r\n}\r\n\r\n.toaster-progress {\r\n  background-color: white;\r\n  height: 4px;\r\n  width: 100%;\r\n  /* Başlangıç genişliği */\r\n  transition: width 0.1s linear;\r\n  /* Genişlik değişimi */\r\n  margin-top: 10px;\r\n}\r\n\r\n@keyframes fadeIn {\r\n  from {\r\n    opacity: 0;\r\n  }\r\n\r\n  to {\r\n    opacity: 1;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
