import React, { createContext, useState, useContext } from 'react';
import Toaster from './components/Toaster/Toaster';

const ToasterContext = createContext();

export const useToaster = () => useContext(ToasterContext);

export const ToasterProvider = ({ children }) => {
    const [toast, setToast] = useState({ message: '', type: '', visible: false });

    const showToaster = (message, type = 'success') => {
        setToast({ message, type, visible: true });
        setTimeout(() => setToast({ message: '', type: '', visible: false }), 3000); // 3 saniye sonra otomatik kaybolma
    };

    const hideToaster = () => {
        setToast({ message: '', type: '', visible: false });
    };

    const value = {
        toastSuccess: (message) => showToaster(message, 'success'),
        toastError: (message) => showToaster(message, 'error'),
        toastWarning: (message) => showToaster(message, 'warning'),
        hide: hideToaster,
    };

    return (
        <ToasterContext.Provider value={value}>
            {children}
            {toast.visible && (
                <Toaster
                    message={toast.message}
                    type={toast.type}
                    duration={toast.duration}
                    onClose={hideToaster}
                    position={{ top: '20px', right: '20px' }}
                />
            )}
        </ToasterContext.Provider>
    );
};
