// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageBox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .messageBox {
    background: var(--background-color);
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    text-align: flex-start;
  }
  
  .messageBox-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .messageBox-message {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  
  .messageBox-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
  
  .messageBox-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 90px;
  }
  
  .messageBox-button.cancel {
    background-color: #aa2f26;
    color: white;
  }
  
  .messageBox-button.confirm {
    background-color: #327434;
    color: white;
  }
  
  .messageBox-button:hover {
    opacity: 0.8;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/MessageBox/MessageBox.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;;EAEA;IACE,mCAAmC;IACnC,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,2CAA2C;IAC3C,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,SAAS;EACX;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,YAAY;EACd","sourcesContent":[".messageBox-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100vw;\r\n    height: 100vh;\r\n    background: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 1000;\r\n  }\r\n  \r\n  .messageBox {\r\n    background: var(--background-color);\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    max-width: 500px;\r\n    width: 100%;\r\n    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);\r\n    text-align: flex-start;\r\n  }\r\n  \r\n  .messageBox-title {\r\n    font-size: 20px;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .messageBox-message {\r\n    font-size: 13px;\r\n    line-height: 20px;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .messageBox-buttons {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    gap: 15px;\r\n  }\r\n  \r\n  .messageBox-button {\r\n    padding: 10px 20px;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n    width: 90px;\r\n  }\r\n  \r\n  .messageBox-button.cancel {\r\n    background-color: #aa2f26;\r\n    color: white;\r\n  }\r\n  \r\n  .messageBox-button.confirm {\r\n    background-color: #327434;\r\n    color: white;\r\n  }\r\n  \r\n  .messageBox-button:hover {\r\n    opacity: 0.8;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
