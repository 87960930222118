import React, { createContext, useEffect, useState } from "react";
import Services from "./services/Services";
import CircularProgress from "./components/CircularProgress/CircularProgress";
import { useLanguage } from "./LanguageProvider";
import { useToaster } from "./ToasterProvider";

const GeneralContext = createContext();

const GeneralProvider = ({ children }) => {
  const { getTranslation } = useLanguage();
  const { toastError, toastSuccess } = useToaster();

  const [activeKronos, setActiveKronos] = useState(null);
  const [timerInterval, setTimerInterval] = useState(null);

  const [selectedApi, setSelectedApi] = useState({});
  const [selectedDocumentation, setSelectedDocumentation] = useState({});
  const [collections, setCollections] = useState([]);
  const [currentPage, setCurrentPage] = useState("projects");
  const [bearerToken, setBearerToken] = useState();
  const [activeTab, setActiveTab] = useState("authorization");
  const [addToCollectionId, setAddToCollectionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [profilePageSection, setProfilePageSection] = useState("profileInfo");
  const [panels, setPanels] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState({});
  const [selectedPanelId, setSelectedPanelId] = useState("");
  const [addToPanelId, setAddToPanelId] = useState("");
  const [projects, setProjects] = useState([]);
  const [isAddCollectionModalOpen, setIsAddCollectionModalOpen] =
    useState(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [isAddPanelModalOpen, setIsAddPanelModalOpen] = useState(false);
  const [addBoardModalOpen, setAddBoardModalOpen] = useState(false);
  const [selectedProjectID, setSelectedProjectID] = useState("");

  const sessionToken = sessionStorage.getItem("token");
  const localToken = localStorage.getItem("token");
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));
  const localUser = JSON.parse(localStorage.getItem("user"));

  const isAuthenticated = !!sessionToken || !!localToken;
  const userId = sessionUser ? sessionUser?.id : localUser?.id;

  useEffect(() => {
    getAllProjects(localToken ? localToken : sessionToken);
    // eslint-disable-next-line
  }, [localToken, sessionToken]);

  useEffect(() => {
    setToken(localToken ? localToken : sessionToken);
    getAllCollections(localToken ? localToken : sessionToken);
    getAllPanels(localToken ? localToken : sessionToken);
    // eslint-disable-next-line
  }, [localToken, sessionToken, selectedProjectID]);

  useEffect(() => {
    if (activeKronos && activeKronos.startTime) {
      const updateTitle = () => {
        const elapsedTime = Math.floor(
          (Date.now() - activeKronos.startTime) / 1000
        );
        const hours = Math.floor(elapsedTime / 3600)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((elapsedTime % 3600) / 60)
          .toString()
          .padStart(2, "0");
        const seconds = (elapsedTime % 60).toString().padStart(2, "0");
        document.title = `${hours}:${minutes}:${seconds} - ${activeKronos.title}`;
      };

      updateTitle();
      const interval = setInterval(updateTitle, 1000);
      setTimerInterval(interval);

      return () => {
        clearInterval(interval);
        document.title = "Apiollon";
      };
    } else {
      document.title = "Apiollon";
    }
  }, [activeKronos]);

  useEffect(() => {
    async function initializeActiveKronos() {
      try {
        const currentKronos = await handleGetCurrentKronos();
        if (currentKronos?.status) {
          const { kronosTitle, startDateTime } = currentKronos.result;

          setActiveKronos({
            title: kronosTitle,
            startTime: new Date(startDateTime * 1000).getTime(),
          });
        }
      } catch (error) {
        console.error("Error initializing active Kronos:", error);
      }
    }

    initializeActiveKronos();
  }, [selectedProjectID]);

  //General Functions

  const handleTokenUpdate = (newToken) => {
    if (newToken) {
      setToken(newToken);
      getAllCollections(newToken);
      getAllPanels(newToken);
    }
  };

  const handlePageChange = (page) => {
    setProfilePageSection("profileInfo");
    if (page === "collections" && Object.keys(selectedApi).length > 0) {
      setCurrentPage("apis");
    } else {
      setCurrentPage(page);
      if (page === "profile") {
        setSelectedApi({});
        setAddToCollectionId("");
        setAddToPanelId("");
      }
    }
  };

  const handleAddNewApi = (collectionId) => {
    setAddToCollectionId(collectionId);
    setSelectedApi({});
    setCurrentPage("apis");
  };

  const handleAddNewBoard = (panelId) => {
    setAddToPanelId(panelId);
    setSelectedBoard({});
  };

  const handleBearerTokenChange = (token) => {
    setBearerToken(token);
  };

  const handleSelectApi = (api, collectionId) => {
    setAddToCollectionId(collectionId);
    if (currentPage === "collections") {
      setCurrentPage("apis");
    }

    if (api.apiId === selectedApi.apiId) {
      setSelectedApi({});
      if (currentPage === "apis") {
        setCurrentPage("collections");
      }
    } else {
      let body =
        api.body &&
        JSON.stringify(api.body) !== "{}" &&
        typeof api.body !== "string"
          ? JSON.stringify(api.body)
          : api.body;

      if (api.body.length === 0) {
        body = "{}";
      }

      setSelectedApi({
        ...api,
        body,
      });

      Services.GetDocumentationByApiID(token, api.apiId)
        .then((res) => {
          if (res.data.status) {
            setSelectedDocumentation(res.data.result[0]);
          } else {
            toastError(getTranslation("somethingWentWrong"));
            console.log(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleProfileSectionChange = (section) => {
    setProfilePageSection(section);
  };

  const handleThemeChange = (theme) => {
    Services.ChangeTheme(token, userId, theme)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  };

  // API Functions
  const handleAddApi = (api, response) => {
    // API Create ederken basic-auth ise username password yolla
    if (Object.keys(selectedApi).length === 0) {
      // setIsLoading(true)
      const userID = sessionUser ? sessionUser?.id : localUser?.id;
      let members = [{ id: userID, permission: "admin" }];

      Services.CreateAPI(
        token,
        addToCollectionId,
        api.apiTitle,
        api.method,
        api.url,
        api.auth,
        api.headers,
        api.body,
        members
      )
        .then((res) => {
          if (res.data.status) {
            const receivedData = res.data.result;

            const updatedData = {
              ...receivedData,
              response: response,
            };

            const parameters = {
              body: receivedData.body,
              url: receivedData.url,
              title: receivedData.title,
              method: receivedData.method,
            };

            Services.CreateDocumentation(
              token,
              addToCollectionId,
              receivedData.apiId,
              api.apiTitle,
              parameters
            )
              .then((res) => {
                if (res.data.status) {
                  getAllCollections();
                  setSelectedApi(updatedData);
                  toastSuccess(getTranslation("successMessage"));
                } else {
                  toastError(getTranslation("somethingWentWrong"));
                  console.log(res.data);
                }
              })
              .catch((err) => console.log(err))
              .finally(() => setIsLoading(false));
          } else {
            toastError(getTranslation("somethingWentWrong"));
            console.log(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleUpdateApi = (apiId, updatedApi) => {
    // API Update ederken basic-auth ise username password yolla

    setIsLoading(true);

    const title =
      selectedApi.apiTitle === updatedApi.apiTitle ? null : updatedApi.apiTitle;

    Services.UpdateAPI(
      token,
      addToCollectionId,
      apiId,
      title,
      updatedApi.method,
      updatedApi.url,
      updatedApi.auth,
      updatedApi.headers,
      updatedApi.body
    )
      .then((res) => {
        if (res.data.status) {
          getAllCollections();
          const modifiedResult = {
            ...res.data.result,
            apiTitle: res.data.result.title
              ? res.data.result.title
              : selectedApi.apiTitle,
            apiId: selectedApi.apiId,
          };
          delete modifiedResult.title;
          setSelectedApi(modifiedResult);
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleSaveAsApi = (api, response) => {
    setIsLoading(true);
    const userID = sessionUser ? sessionUser?.id : localUser?.id;
    let members = [{ id: userID, permission: "admin" }];

    Services.CreateAPI(
      token,
      addToCollectionId,
      api.apiTitle,
      api.method,
      api.url,
      api.auth,
      api.headers,
      api.body,
      members
    )
      .then((res) => {
        if (res.data.status) {
          const receivedData = res.data.result;

          const parameters = {
            body: receivedData.body,
            url: receivedData.url,
            title: receivedData.title,
            method: receivedData.method,
          };

          const updatedData = {
            ...receivedData,
            response: response,
          };

          Services.CreateDocumentation(
            token,
            addToCollectionId,
            receivedData.apiId,
            api.apiTitle,
            parameters
          )
            .then((res) => {
              if (res.data.status) {
                getAllCollections();
                setSelectedApi(updatedData);
                toastSuccess(getTranslation("successMessage"));
              } else {
                toastError(getTranslation("somethingWentWrong"));
                console.log(res.data);
              }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));

          getAllCollections();
          setSelectedApi(res.data.result);
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleUpdateDocumentation = (apiId, id, updateData) => {
    setIsLoading(true);

    Services.UpdateDocumentation(token, apiId, id, updateData)
      .then((res) => {
        if (res.data.status) {
          toastSuccess(getTranslation("successMessage"));
          setSelectedDocumentation({
            ...selectedDocumentation,
            parameters: res.data.result.parameters,
            description: res.data.result.description,
          });
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleDeleteApi = (apiId) => {
    setIsLoading(true);
    Services.DeleteAPI(token, apiId)
      .then((res) => {
        if (res.data.status) {
          getAllCollections();
          setSelectedApi({});
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));

    if (currentPage === "apis") {
      setCurrentPage("collections");
    }
  };

  // Projects Functions

  const handleAddNewProject = (title) => {
    setIsLoading(true);
    const userID = sessionUser ? sessionUser?.id : localUser?.id;
    let members = [{ id: userID, permission: "write" }];
    Services.CreateProject(token, title, members)
      .then((res) => {
        if (res.data.status) {
          getAllProjects();
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleUpdateProjectTitle = (projectId, newName) => {
    setIsLoading(true);
    Services.UpdateProject(token, projectId, newName)
      .then((res) => {
        if (res.data.status) {
          getAllProjects();
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleDeleteProject = (projectId) => {
    setIsLoading(true);
    Services.DeleteProject(token, projectId)
      .then((res) => {
        if (res.data.status) {
          getAllProjects();
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const getAllProjects = (inFuncToken) => {
    setIsLoading(true);

    let useToken = inFuncToken ? inFuncToken : token;
    Services.GetProjects(useToken)
      .then((res) => {
        if (res.data.status) {
          setProjects(res.data.result);
          if (
            res.data.result.length === 1 ||
            !res.data.result.some(
              (project) => project.projectId === selectedProjectID
            )
          ) {
            handleSelectProject(res.data.result[0].projectId, useToken);
          }
        } else {
          setProjects([]);
          handleSelectProject("");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleSelectProject = (projectId, inFuncToken) => {
    let useToken = inFuncToken ? inFuncToken : token;
    const userID = sessionUser ? sessionUser?.id : localUser?.id;

    Services.SetUserSelectedProjectId(useToken, userID, projectId)
      .then((res) => {
        if (res.data.status) {
          setSelectedProjectID(projectId);
          setSelectedApi({});
          setSelectedBoard({});
        } else {
          setSelectedProjectID("");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  // Collection Functions
  const handleAddNewCollection = (name) => {
    setIsLoading(true);
    const userID = sessionUser ? sessionUser?.id : localUser?.id;
    let members = [{ id: userID, permission: "admin" }];
    Services.CreateCollection(token, name, userID, members, selectedProjectID)
      .then((res) => {
        if (res.data.status) {
          getAllCollections();
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const getAllCollections = (inFuncToken) => {
    setIsLoading(true);
    let useToken = inFuncToken ? inFuncToken : token;
    Services.GetCollections(useToken, selectedProjectID)
      .then((res) => {
        if (res.data.status) {
          setCollections(res.data.result);
        } else {
          setCollections([]);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleUpdateCollectionTitle = (collectionId, newName) => {
    setIsLoading(true);
    Services.UpdateCollection(token, collectionId, newName)
      .then((res) => {
        if (res.data.status) {
          getAllCollections();
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleDeleteCollection = (collectionId) => {
    setIsLoading(true);
    Services.DeleteCollection(token, collectionId)
      .then((res) => {
        if (res.data.status) {
          getAllCollections();
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));

    if (selectedApi.collectionId === collectionId) {
      setSelectedApi({});
    }

    if (currentPage === "apis") {
      setCurrentPage("collections");
    }
  };

  //User Functions
  const handleUpdateUser = (
    userId,
    name,
    lastname,
    email,
    phone,
    countryCode
  ) => {
    setIsLoading(true);

    return new Promise((resolve, reject) => {
      Services.UpdateUser(
        token,
        userId,
        name,
        lastname,
        email,
        phone,
        countryCode
      )
        .then((res) => {
          if (res.data.status) {
            toastSuccess(getTranslation("successMessage"));
            resolve(true); // İşlem başarılı olursa resolve ile true döndür
          } else {
            toastError(getTranslation("somethingWentWrong"));
            resolve(false); // İşlem başarısız olursa resolve ile false döndür
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err); // Hata durumunda reject ile Promise'ı reddet
        })
        .finally(() => setIsLoading(false));
    });
  };

  const handleUpdateUserPassword = (userId, password) => {
    setIsLoading(true);

    return new Promise((resolve, reject) => {
      Services.UpdateUserPassword(token, userId, password)
        .then((res) => {
          if (res.data.status) {
            toastSuccess(getTranslation("successMessage"));
            resolve(true); // İşlem başarılı olursa resolve ile true döndür
          } else {
            toastError(getTranslation("somethingWentWrong"));
            resolve(false); // İşlem başarısız olursa resolve ile false döndür
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err); // Hata durumunda reject ile Promise'ı reddet
        })
        .finally(() => setIsLoading(false));
    });
  };

  //Task Management Functions
  const getAllPanels = (inFuncToken, type) => {
    if (type !== "noReload") {
      setIsLoading(true);
    }

    let useToken = inFuncToken ? inFuncToken : token;
    Services.GetPanels(useToken, selectedProjectID)
      .then((res) => {
        if (res.data.status) {
          setPanels(res.data.result);
          if (type === "taskAdd" || type === "noReload") {
            const selectedPanelVar = res.data.result.find(
              (panel) => panel.panelId === selectedPanelId
            );
            const selectedBoardVar = selectedPanelVar.boards.find(
              (board) => board.boardId === selectedBoard.boardId
            );
            setSelectedBoard(selectedBoardVar);
          }
        } else {
          setPanels([]);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleAddNewPanel = (name) => {
    setIsLoading(true);

    const userID = sessionUser ? sessionUser?.id : localUser?.id;

    let members = [{ id: userID, permission: "admin" }];

    Services.CreatePanel(token, name, members, selectedProjectID)
      .then((res) => {
        if (res.data.status) {
          getAllPanels();
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleUpdatePanelTitle = (panelId, newName) => {
    setIsLoading(true);
    Services.UpdatePanel(token, panelId, newName)
      .then((res) => {
        if (res.data.status) {
          getAllPanels();
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleDeletePanel = (panelId) => {
    setIsLoading(true);
    Services.DeletePanel(token, panelId)
      .then((res) => {
        if (res.data.status) {
          getAllPanels();
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));

    if (selectedPanelId === panelId) {
      setSelectedBoard({});
    }
  };

  const handleAddBoard = (newBoard) => {
    const transformBoardData = (data) => {
      return {
        boardId: data.boardId,
        boardTitle: data.title,
        boardTeamId: data.teamId ? data.teamId : "", // Eğer `teamId` false ise boş string
        boardCreatedBy: data.createdBy,
        boardMembers: data.members,
        tasks: [], // Boş array olarak ayarlanmış
      };
    };

    if (Object.keys(selectedBoard).length === 0) {
      const userID = sessionUser ? sessionUser?.id : localUser?.id;

      let members = [{ id: userID, permission: "admin" }];
      // setIsLoading(true)
      const targetPanel = panels.find(
        (panel) => panel.panelId === addToPanelId
      );

      Services.CreateBoard(
        token,
        addToPanelId,
        newBoard.title,
        members,
        selectedProjectID
      )
        .then((res) => {
          if (res.data.status) {
            getAllPanels();
            setSelectedBoard(transformBoardData(res.data.result));
            setSelectedPanelId(targetPanel.panelId);
            toastSuccess(getTranslation("successMessage"));
          } else {
            toastError(getTranslation("somethingWentWrong"));
            console.log(res.data);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const handleSelectBoard = (board, panelId) => {
    setAddToPanelId(panelId);
    if (board.boardId === selectedBoard?.boardId) {
      setSelectedBoard({});
      setSelectedPanelId("");
    } else {
      setSelectedBoard(board);
      setSelectedPanelId(panelId);
    }
  };

  const handleUpdateBoardTitle = (boardId, newTitle) => {
    setIsLoading(true);

    Services.UpdateBoardTitle(token, boardId, newTitle)
      .then((res) => {
        if (res.data.status) {
          getAllPanels();
          toastSuccess(getTranslation("successMessage"));
          setSelectedBoard({ ...selectedBoard, boardTitle: newTitle });
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleDeleteBoard = (boardId) => {
    setIsLoading(true);
    Services.DeleteBoard(token, boardId)
      .then((res) => {
        if (res.data.status) {
          getAllPanels();
          toastSuccess(getTranslation("successMessage"));
          setSelectedBoard({});
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleAddTask = (title, subtitle, description, taskStart, taskEnd) => {
    const userID = sessionUser ? sessionUser?.id : localUser?.id;
    let members = [{ id: userID, permission: "admin" }];

    Services.CreateTask(
      token,
      title,
      subtitle,
      description,
      selectedPanelId,
      selectedBoard.boardId,
      members,
      taskStart,
      taskEnd,
      selectedProjectID
    )
      .then((res) => {
        if (res.data.status) {
          getAllPanels(null, "taskAdd");
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleUpdateTaskDates = (taskId, taskStart, taskEnd) => {
    Services.UpdateTaskDates(token, taskId, taskStart, taskEnd)
      .then((res) => {
        if (res.data.status) {
          getAllPanels(null, "noReload");
          // toastSuccess(getTranslation('successMessage'))
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleLeaveTask = (taskId) => {
    Services.LeaveTask(token, taskId)
      .then((res) => {
        if (res.data.status) {
          getAllPanels(null, "noReload");
          // toastSuccess(getTranslation('successMessage'))
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleCreateTodo = (taskId, todoList) => {
    Services.CreateTodo(token, taskId, todoList)
      .then((res) => {
        if (res.data.status) {
          getAllPanels(null, "noReload");
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleUpdateTodo = (taskId, todoList) => {
    Services.UpdateTodo(token, taskId, todoList)
      .then((res) => {
        if (res.data.status) {
          getAllPanels(null, "noReload");
          // toastSuccess(getTranslation('successMessage'))
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleChangeTaskStatus = (taskId, status) => {
    Services.ChangeTaskStatus(token, taskId, status)
      .then((res) => {
        if (res.data.status) {
          getAllPanels(null, "noReload");
          // toastSuccess(getTranslation('successMessage'))
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleUpdateTaskDescription = (taskId, description) => {
    Services.UpdateTaskDescription(token, taskId, description)
      .then((res) => {
        if (res.data.status) {
          getAllPanels(null, "noReload");
          // toastSuccess(getTranslation('successMessage'))
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleDeleteTask = (taskId) => {
    Services.DeleteTask(token, taskId)
      .then((res) => {
        if (res.data.status) {
          getAllPanels(null, "noReload");
          toastSuccess(getTranslation("successMessage"));
        } else {
          toastError(getTranslation("somethingWentWrong"));
          console.log(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  // Kronos start
  const handleGetCurrentKronos = async () => {
    try {
      const response = await Services.GetCurrentKronos(token);

      console.log(
        "Provider --> Servisten dönen GetCurrentKronos response.data: ",
        response.data
      );
      if (response?.data?.result) {
        const { kronosTitle, startDateTime } = response?.data?.result;
        setActiveKronos({
          title: kronosTitle,
          startTime: new Date(startDateTime * 1000).getTime(),
        });
        return {
          status: true,
          result: response?.data?.result,
        };
      } else {
        return { status: false };
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Error in KronosCreateAndStart:", error);
      return { status: false };
    }
  };

  const handleKronosCreateAndStart = async (timeLog) => {
    try {
      const response = await Services.KronosCreateAndStart(
        token,
        selectedProjectID,
        timeLog.title,
        timeLog.members
      );

      console.log(
        "Provider --> Servisten dönen response.data: ",
        response.data
      );
      if (response.data.status) {
        const startTime = Date.now();
        setActiveKronos({ title: timeLog.title, startTime });
        return {
          status: true,
          result: {
            kronosId: response.data.result.kronosId,
            logId: response.data.result.logId,
          },
          timestamp: response.data.timestamp,
        };
      } else {
        toastError(getTranslation("somethingWentWrong"));
        return { status: false };
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Error in KronosCreateAndStart:", error);
      return { status: false };
    }
  };

  const handleKronosStop = async (logId) => {
    try {
      const response = await Services.KronosStop(token, logId);

      console.log(
        "Provider --> Servisten dönen stop response.data: ",
        response.data
      );
      if (response.data.status) {
        setActiveKronos(null);
        if (timerInterval) {
          clearInterval(timerInterval);
        }
        return response.data;
      } else {
        toastError(getTranslation("somethingWentWrong"));
        return { status: false };
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Error in KronosCreateAndStart:", error);
      return { status: false };
    }
  };

  const handleKronosGetByToken = async () => {
    try {
      const response = await Services.GetKronosByToken(
        token,
        selectedProjectID
      );

      console.log(
        "Provider --> Servisten dönen Get kronos by Token response.data: ",
        response.data
      );
      if (response.data.status) {
        return response.data;
      } else {
        toastError(getTranslation("somethingWentWrong"));
        return { status: false };
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Error in KronosCreateAndStart:", error);
      return { status: false };
    }
  };

  const handleManualKronosCreate = async (
    title,
    members,
    startDate,
    endDate
  ) => {
    setIsLoading(true);
    try {
      const response = await Services.KronosCreateManual(
        token,
        title,
        selectedProjectID,
        members,
        startDate,
        endDate
      );

      console.log(
        "Provider --> Servisten dönen manual create kronos response.data: ",
        response.data
      );
      if (response.data.status) {
        toastSuccess(getTranslation("successMessage"));
        return response.data;
      } else {
        toastError(getTranslation("somethingWentWrong"));
        return { status: false };
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Error in manual create kronos:", error);
      return { status: false };
    } finally {
      setIsLoading(false);
    }
  };

  const handleKronosDelete = async (kronosIds) => {
    try {
      const response = await Services.KronosDelete(token, kronosIds);

      console.log(
        "Provider --> Servisten dönen delete kronos response.data: ",
        response.data
      );
      if (response.data.status) {
        toastSuccess(getTranslation("successMessage"));
        return response.data;
      } else {
        toastError(getTranslation("somethingWentWrong"));
        return { status: false };
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Error in Kronos Delete:", error);
      return { status: false };
    }
  };

  const handleKronosDiscard = async (kronosId) => {
    console.log(kronosId);
    try {
      const response = await Services.KronosDiscard(token, kronosId);

      console.log(
        "Provider --> Servisten dönen discard kronos response.data: ",
        response.data
      );
      if (response.data.status) {
        toastSuccess(getTranslation("successMessage"));
        return response.data;
      } else {
        toastError(getTranslation("somethingWentWrong"));
        return { status: false };
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Error in Kronos Discard:", error);
      return { status: false };
    }
  };
  // Kronos ending

  return (
    <GeneralContext.Provider
      value={{
        currentPage,
        handlePageChange,
        bearerToken,
        handleBearerTokenChange,
        handleAddApi,
        handleSelectApi,
        selectedApi,
        handleAddNewApi,
        handleUpdateApi,
        handleSaveAsApi,
        activeTab,
        setActiveTab,
        projects,
        handleAddNewProject,
        handleUpdateProjectTitle,
        handleDeleteProject,
        _isAddCollectionModalOpen: isAddCollectionModalOpen,
        _setIsAddCollectionModalOpen: setIsAddCollectionModalOpen,
        _isAddProjectModalOpen: isAddProjectModalOpen,
        _setIsAddProjectModalOpen: setIsAddProjectModalOpen,
        _isAddPanelModalOpen: isAddPanelModalOpen,
        _setIsAddPanelModalOpen: setIsAddPanelModalOpen,
        _addBoardModalOpen: addBoardModalOpen,
        _setAddBoardModalOpen: setAddBoardModalOpen,
        handleAddNewCollection,
        collections,
        addToCollectionId,
        handleUpdateCollectionTitle,
        handleDeleteCollection,
        isAuthenticated,
        handleTokenUpdate,
        profilePageSection,
        handleProfileSectionChange,
        handleUpdateUser,
        handleUpdateUserPassword,
        panels,
        selectedBoard,
        selectedPanelId,
        handleAddNewPanel,
        handleUpdatePanelTitle,
        handleSelectBoard,
        handleAddNewBoard,
        handleAddBoard,
        handleDeletePanel,
        handleUpdateBoardTitle,
        handleDeleteBoard,
        handleAddTask,
        userId,
        handleCreateTodo,
        handleUpdateTodo,
        handleUpdateTaskDates,
        handleChangeTaskStatus,
        handleUpdateTaskDescription,
        handleDeleteTask,
        selectedDocumentation,
        handleUpdateDocumentation,
        handleThemeChange,
        handleDeleteApi,
        handleLeaveTask,
        selectedProjectID,
        handleSelectProject,
        // Kronos start
        handleGetCurrentKronos,
        handleKronosCreateAndStart,
        handleKronosStop,
        handleKronosGetByToken,
        handleManualKronosCreate,
        handleKronosDelete,
        handleKronosDiscard,
        // Kronos ending
      }}
    >
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            pointerEvents: "none",
          }}
        >
          <CircularProgress style={{ width: "30px", height: "30px" }} />
          <div
            style={{
              position: "absolute",
              width: "100%",
              backgroundColor: "#000",
              opacity: "0.1",
            }}
          >
            {children}
          </div>
        </div>
      ) : (
        children
      )}
    </GeneralContext.Provider>
  );
};

export { GeneralProvider, GeneralContext };
