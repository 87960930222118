// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    /* Tam ekran ortalamak için */
}

.spinner {
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* Yarı saydam bir dış kenar */
    border-top: 1px solid #d8681d;
    /* Dönecek olan renkli kenar */
    border-radius: 50%;
    /* Daire yapmak için */
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
    /* Sürekli dönen animasyon */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CircularProgress/CircularProgress.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,oCAAoC;IACpC,8BAA8B;IAC9B,6BAA6B;IAC7B,8BAA8B;IAC9B,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,4BAA4B;AAChC;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".spinner-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100%;\r\n    width: 100%;\r\n    /* Tam ekran ortalamak için */\r\n}\r\n\r\n.spinner {\r\n    border: 1px solid rgba(0, 0, 0, 0.1);\r\n    /* Yarı saydam bir dış kenar */\r\n    border-top: 1px solid #d8681d;\r\n    /* Dönecek olan renkli kenar */\r\n    border-radius: 50%;\r\n    /* Daire yapmak için */\r\n    width: 15px;\r\n    height: 15px;\r\n    animation: spin 1s linear infinite;\r\n    /* Sürekli dönen animasyon */\r\n}\r\n\r\n@keyframes spin {\r\n    0% {\r\n        transform: rotate(0deg);\r\n    }\r\n\r\n    100% {\r\n        transform: rotate(360deg);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
