import React from 'react';
import './CircularProgress.css'; // CSS dosyasını import ediyoruz

const CircularProgress = ({style}) => {
  return (
    <div className="spinner-container">
      <div className="spinner" style={style}></div>
    </div>
  );
};

export default CircularProgress;
