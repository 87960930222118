import React from 'react';
import { useLanguage } from '../../../LanguageProvider';
import { FaPlus } from "react-icons/fa6";

export default function Headers({ headers, setHeaders }) {
    const { getTranslation } = useLanguage();

    const handleAddHeader = () => {
        setHeaders([...headers, { key: '', value: '', description: '', selected: false }]);
    };

    const handleDeleteHeader = (index) => {
        setHeaders(headers.filter((_, i) => i !== index));
    };

    const handleInputChange = (index, field, value) => {
        setHeaders(headers.map((header, i) =>
            i === index ? { ...header, [field]: value } : header
        ));
    };

    const handleCheckboxChange = (index) => {
        setHeaders(headers.map((header, i) =>
            i === index ? { ...header, selected: !header.selected } : header
        ));
    };

    return (
        <div className='card' style={{ marginBottom: '25px' }}>
            <button style={{ alignItems: 'center', display: 'flex' }} onClick={handleAddHeader}>
                <FaPlus style={{ marginRight: '10px' }} /> {getTranslation('addHeader')}
            </button>
            <div className='card' style={{  maxHeight: '320px', overflowY: 'auto', marginTop: '15px', boxShadow:'none' }}>
                <table style={{ width: '100%', marginTop: '15px' }}>
                    <tbody>
                        {headers.map((header, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={header.selected || false}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={header.key}
                                        placeholder={getTranslation('key')}
                                        onChange={(e) =>
                                            handleInputChange(index, 'key', e.target.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={header.value}
                                        placeholder={getTranslation('value')}
                                        onChange={(e) =>
                                            handleInputChange(index, 'value', e.target.value)
                                        }
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={header.description}
                                        placeholder={getTranslation('description')}
                                        onChange={(e) =>
                                            handleInputChange(index, 'description', e.target.value)
                                        }
                                    />
                                </td>
                                <td style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                    <button
                                        style={{ height: '45px' }}
                                        disabled={headers.length === 1}
                                        onClick={() => handleDeleteHeader(index)}
                                    >
                                        {getTranslation('delete')}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    );
}
