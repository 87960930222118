import React, { useContext } from 'react'
import { useLanguage } from '../../../LanguageProvider';
import VerticalDivider from '../../../components/VerticalDivider';
import { GeneralContext } from '../../../GeneralProvider';

export default function Authorization({ selectedAuth, setSelectedAuth, basicAuth, handleChangeBasicAuth }) {
    const { getTranslation} = useLanguage();
    const { bearerToken, handleBearerTokenChange } = useContext(GeneralContext)

    const handleAuthChange = (event) => {
        setSelectedAuth(event.target.value);
    };

    return (
        <div className='card' style={{ height: '35vh', flexDirection: 'row', display: 'flex' }}>
            <select style={{ width: '300px' }} value={selectedAuth} onChange={handleAuthChange}>
                <option value="no-auth">No Auth</option>
                <option value="basic-auth">Basic Auth</option>
                <option value="Bearer">Bearer Auth</option>
            </select>
            <VerticalDivider />
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%' }}>
                {selectedAuth === "no-auth" && <p style={{ fontSize: '12px' }}>{getTranslation('noAuth')}</p>}
                {selectedAuth === "basic-auth" &&
                    <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <p style={{ width: '200px' }}>{getTranslation('username')} :</p>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                value={basicAuth.username}
                                onChange={handleChangeBasicAuth}
                                style={{ width: '350px' }}
                                placeholder={getTranslation('username')}
                            />
                        </div>

                        <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex' }}>
                            <p style={{ width: '200px' }}>{getTranslation('password')} :</p>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={basicAuth.password}
                                onChange={handleChangeBasicAuth}
                                style={{ width: '350px' }}
                                placeholder={getTranslation('password')}
                            />
                        </div>
                    </div>
                }
                {selectedAuth === "Bearer" &&
                    <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <p style={{ width: '200px' }}>Bearer Token :</p>
                            <input
                                type="text"
                                id="bearerToken"
                                name="bearerToken"
                                value={bearerToken}
                                onChange={(e) => handleBearerTokenChange(e.target.value)}
                                style={{ width: '350px' }}
                                placeholder='Token'
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}