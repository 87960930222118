import React, { useContext } from 'react';
import Calendar from './Views/Calendar';
import { GeneralContext } from '../../GeneralProvider';
import { useLanguage } from '../../LanguageProvider';

export default function TaskManager() {
  const { getTranslation } = useLanguage();
  const { selectedBoard, panels, _setIsAddPanelModalOpen } = useContext(GeneralContext);

  const checkIfBoardsAreEmpty = (panels) => {
    return panels.every(panel => panel.boards.length === 0);
  };

  const allBoardsEmpty = checkIfBoardsAreEmpty(panels);

  return (
    <div className='main-page'>
      {panels.length === 0 ? (
        <div
          style={{
            width: "100%",
            height: "65%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <button onClick={() => _setIsAddPanelModalOpen(true)}>
            {getTranslation('createNewPanel')}
          </button>
        </div>
      ) : (
        <>
          {allBoardsEmpty ? (
            <div style={{ width: '100%', height: '65%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              {getTranslation('addABoard')}
            </div>
          ) : (
            <>
              {Object.keys(selectedBoard).length !== 0 ? (
                <Calendar />
              ) : (
                <div style={{ width: '100%', height: '65%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                  {getTranslation('selectABoard')}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
