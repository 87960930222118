import React, { Suspense, useState } from 'react';
import './Signup.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLanguage } from '../../LanguageProvider';
import { Link, useNavigate } from 'react-router-dom';
import LanguageSelect from '../../components/LanguageSelect';
import countryCodes from './countryCodes.json'
import ThreeSisters from '../../components/ThreeSisters/ThreeSisters';
import Logo from '../../assets/ApiollonLogo.png'
import Services from '../../services/Services';
import CustomPhoneSelect from '../../components/CustomPhoneSelect/CustomPhoneSelect';
import { useToaster } from '../../ToasterProvider';
import MainPageBG from '../../assets/mainpagebg.jpg'

export default function Signup() {

  const { getTranslation } = useLanguage();
  const { toastSuccess } = useToaster();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+90");

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const [errors, setErrors] = useState([]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordRepeatVisibility = () => {
    setShowPasswordRepeat(!showPasswordRepeat);
  };

  const validateInputs = () => {
    const newErrors = [];

    // Username validation
    if (username.length < 3 || username.length > 20 || /[^\w]/.test(username)) {
      newErrors.push('usernameError');
    }

    // Name validation
    if (name.length < 2 || name.length > 50 || /[^a-zA-Z' ]/g.test(name)) {
      newErrors.push('nameError');
    }

    // Lastname validation
    if (lastName.length < 1 || lastName.length > 50 || /[^a-zA-Z']/g.test(lastName)) {
      newErrors.push('lastnameError');
    }

    // Password validation
    if (!/^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,})$/.test(password)) {
      newErrors.push('passwordError');
    }

    // Password Check validation
    if (password !== passwordRepeat) {
      newErrors.push('passwordMatchError');
    }

    // Phone validation
    if (!/^\d{10}$/.test(phone)) {
      newErrors.push('phoneError');
    }

    // Email validation
    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      newErrors.push('emailError');
    }

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const userRegister = () => {
    if (!validateInputs()) return;

    Services.GetToken()
      .then((res) => {
        if (res.data.status) {
          Services.UserRegister(res.data.result.token, name, lastName, username, password, phone, countryCode, email)
            .then((res) => {
              if (res.data.status) {
                toastSuccess(getTranslation('successMessage'))
                navigate('/');
              }
              else {
                const newErrors = [];

                if (res.data.message.includes("username already exists")) {
                  newErrors.push("userNameExist")
                }
                if (res.data.message.includes("email already exists")) {
                  newErrors.push("emailExist")
                }
                if (res.data.message.includes('phone already exists')) {
                  newErrors.push('phoneExist')
                }
                setErrors(newErrors);
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));


  };

  const imageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // objectFit: 'cover',
    zIndex: 0,
  };


  return (
    <div className='signup-main'>

      {/* <Suspense fallback={null}>
        <ThreeSisters />
      </Suspense> */}

      <img
        src={MainPageBG}
        alt="Background"
        style={imageStyle}
      />

      <div style={{ position: 'absolute', top: 10, right: 15 }}>
        <LanguageSelect />
      </div>

      {/* <img
        src={Logo}
        alt={"Logo"}
        style={{ zIndex: 99, width: '200px', marginBottom: '25px' }}
      /> */}
      <div className='login-card'>
        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <p style={{ fontSize: '24px' }}>{getTranslation('signup')}</p>
        </div>

        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('name')} :</p>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: '350px' }}
            placeholder={getTranslation('name')}
          />
        </div>

        <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex' }}>
          <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('lastname')} :</p>
          <input
            type="text"
            id="lastname"
            name="lastname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={{ width: '350px' }}
            placeholder={getTranslation('lastname')}
          />
        </div>

        <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex' }}>
          <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('username')} :</p>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ width: '350px' }}
            placeholder={getTranslation('username')}
          />
        </div>

        <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex' }}>
          <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('email')} :</p>
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '350px' }}
            placeholder={getTranslation('email')}
          />
        </div>

        {/* Telefon numarası alanı */}
        <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('phone')} :</p>
          <div style={{ display: 'flex', alignItems: 'center', width: '350px' }}>
            {/* Ülke kodu select input */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomPhoneSelect
                options={countryCodes}
                value={countryCode}
                onChange={setCountryCode}
              />
            </div>

            {/* Telefon numarası input */}
            <input
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) { // Sadece rakamları kabul et
                  setPhone(value);
                }
              }}
              style={{ width: '260px' }}
              placeholder={getTranslation('phone')}
              maxLength={10}
            />

          </div>
        </div>

        <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('password')} :</p>
          <div style={{ display: 'flex', alignItems: 'center', width: '350px', position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: '100%', paddingRight: '30px' }}
              placeholder={getTranslation('password')}
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '10px',
                cursor: 'pointer',
                color: '#999',
              }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        <div style={{ marginTop: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <p style={{ width: '200px', fontSize: '14px' }}>{getTranslation('passwordRepeat')} :</p>
          <div style={{ display: 'flex', alignItems: 'center', width: '350px', position: 'relative' }}>
            <input
              type={showPasswordRepeat ? 'text' : 'password'}
              id="passwordRepeat"
              name="passwordRepeat"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
              style={{ width: '100%', paddingRight: '30px' }}
              placeholder={getTranslation('passwordRepeat')}
            />
            <span
              onClick={togglePasswordRepeatVisibility}
              style={{
                position: 'absolute',
                right: '10px',
                cursor: 'pointer',
                color: '#999',
              }}
            >
              {showPasswordRepeat ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>

        {errors.length > 0 && (
          <div className='error-messages'>
            {errors.map((error, index) => (
              <p key={index} style={{ color: 'red', fontSize: '14px' }}>- {getTranslation(error)}</p>
            ))}
          </div>
        )}

        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '35px', flexDirection: 'column', gap: '10px' }}>
          <button onClick={() => userRegister()} style={{ width: '100%' }}>{getTranslation('signup')}</button>
          <Link to={'/'} style={{ fontSize: '12px', fontWeight: '400', color: 'inherit', textAlign: 'end' }}>{getTranslation('alreadyHaveAccount')}</Link>
        </div>
      </div>
    </div >
  );
}