import React, { useContext, useEffect, useState, useRef } from 'react';
import Modal from '../../../components/Modal/Modal';
import { useLanguage } from '../../../LanguageProvider';
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import { GeneralContext } from '../../../GeneralProvider';
import { useToaster } from '../../../ToasterProvider';
import MessageBox from '../../../components/MessageBox/MessageBox';

export default function TaskDetailModal({ isOpen, onClose, task }) {
    const { toastError } = useToaster();
    const { getTranslation } = useLanguage();
    const { handleCreateTodo, handleUpdateTodo, handleUpdateTaskDates, handleChangeTaskStatus, handleUpdateTaskDescription, handleDeleteTask, handleLeaveTask } = useContext(GeneralContext);

    const saveButtonRef = useRef(null);

    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [todoList, setTodoList] = useState([]);
    const [tempTodoList, setTempTodoList] = useState([]);
    const [taskStatus, setTaskStatus] = useState(task?.taskStatus);
    const [taskDescription, setTaskDescription] = useState(task?.taskDescription);

    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endDate, setEndDate] = useState('');
    const [endTime, setEndTime] = useState('');
    const [isEditingDates, setIsEditingDates] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);


    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    const localUser = JSON.parse(localStorage.getItem("user"));

    const user = sessionUser ? sessionUser : localUser;

    function checkUserPermission(usersArray, userID) {
        return usersArray.some(user => user.id === userID);
    }

    useEffect(() => {
        setTodoList(task?.taskTodo ? task?.taskTodo : [])
    }, [task]);

    useEffect(() => {
        setStartDate(task?.taskStart?.split(' ')[0]);
        setStartTime(task?.taskStart?.split(' ')[1]?.slice(0, 5));
        setEndDate(task?.taskEnd?.split(' ')[0]);
        setEndTime(task?.taskEnd?.split(' ')[1]?.slice(0, 5));
        // eslint-disable-next-line
    }, []);

    const handleEditTaskDescription = () => {
        if (isEditingDescription) {
            handleUpdateTaskDescription(task?.taskId, taskDescription)
            setIsEditingDescription(false)
        }
        else {
            setIsEditingDescription(true)
        }
    }

    const handleCompleteEditTaskDescription = () => {
        handleUpdateTaskDescription(task?.taskId, taskDescription)
        setIsEditingDescription(false)
    }

    const handleBlur = (e) => {
        if (saveButtonRef.current && !saveButtonRef.current.contains(e.relatedTarget)) {
            handleCompleteEditTaskDescription();
        }
    }

    const handleChangeStatus = (status) => {
        handleChangeTaskStatus(task?.taskId, status)
        setTaskStatus(status.toString())
    }

    const handleStartDateChange = (value) => {
        const startDateTime = new Date(`${value}T${startTime}`);
        const endDateTime = new Date(`${endDate}T${endTime}:00`);
        if (endDateTime >= startDateTime) {
            setStartDate(value)
            handleUpdateTaskDates(task?.taskId, startDateTime, null);
        }
        else {
            toastError(getTranslation('endDateTimeError'));
        }
    }

    const handleStartTimeChange = (value) => {
        const startDateTime = new Date(`${startDate}T${value}`);
        const endDateTime = new Date(`${endDate}T${endTime}:00`);
        if (endDateTime >= startDateTime) {
            setStartTime(value);
            handleUpdateTaskDates(task.taskId, startDateTime, null);
        }
        else {
            toastError(getTranslation('endDateTimeError'));
        }
    }

    const handleEndDateChange = (value) => {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        const endDateTime = new Date(`${value}T${endTime}:00`);
        if (endDateTime >= startDateTime) {
            setEndDate(value)
            handleUpdateTaskDates(task.taskId, null, endDateTime);
        }
        else {
            toastError(getTranslation('endDateTimeError'));
        }
    }

    const handleEndTimeChange = (value) => {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        const endDateTime = new Date(`${endDate}T${value}:00`);
        if (endDateTime >= startDateTime) {
            setEndTime(value);
            handleUpdateTaskDates(task.taskId, null, endDateTime);
        }
        else {
            toastError(getTranslation('endDateTimeError'));
        }
    }

    const renderStatusChip = (status) => {
        if (status === "1") {
            return (
                <span style={{
                    padding: '5px 10px',
                    backgroundColor: '#d4edda',
                    color: '#155724',
                    borderRadius: '15px',
                    fontSize: '12px',
                    marginLeft: '5px',
                    fontWeight: '600'
                }}>
                    {getTranslation('completed')}
                </span>
            );
        } else if (status === "2") {
            return (
                <span style={{
                    padding: '5px 10px',
                    backgroundColor: '#aa2f26',
                    color: '#fff',
                    borderRadius: '15px',
                    fontSize: '12px',
                    marginLeft: '5px',
                    fontWeight: '600'
                }}>
                    {getTranslation('cancelled')}
                </span>
            );
        } else if (status === "3") {
            return (
                <span style={{
                    padding: '5px 10px',
                    backgroundColor: '#fff3cd',
                    color: '#856404',
                    borderRadius: '15px',
                    fontSize: '12px',
                    marginLeft: '5px',
                    fontWeight: '600'
                }}>
                    {getTranslation('pending')}
                </span>
            );
        } else if (status === "4") {
            return (
                <span style={{
                    padding: '5px 10px',
                    backgroundColor: '#fff3cd',
                    color: '#856404',
                    borderRadius: '15px',
                    fontSize: '12px',
                    marginLeft: '5px',
                    fontWeight: '600'
                }}>
                    {getTranslation('inProgress')}
                </span>
            );
        }
    };

    const formatDate = (date) => {
        if (!date) return '';
        const daysOfWeek = [
            getTranslation('sunday'),
            getTranslation('monday'),
            getTranslation('tuesday'),
            getTranslation('wednesday'),
            getTranslation('thursday'),
            getTranslation('friday'),
            getTranslation('saturday')
        ];

        const months = [
            getTranslation('january'),
            getTranslation('february'),
            getTranslation('march'),
            getTranslation('april'),
            getTranslation('may'),
            getTranslation('june'),
            getTranslation('july'),
            getTranslation('august'),
            getTranslation('september'),
            getTranslation('october'),
            getTranslation('november'),
            getTranslation('december')
        ];

        const dayName = daysOfWeek[date.getDay()];
        const dayNumber = date.getDate();
        const monthName = months[date.getMonth()];
        const year = date.getFullYear();

        return `${dayName}, ${dayNumber} ${monthName} ${year}`;
    };

    const handleRemoveTempTodo = (item) => {
        const newTempTodoList = tempTodoList.filter(x => x !== item)
        setTempTodoList(newTempTodoList)
    }

    const handleAddTodo = () => {
        if (inputValue) {
            setTempTodoList([...tempTodoList, { text: inputValue, checked: false }]);
            setInputValue('');
        }
    };

    const handleSaveTodoList = () => {
        setTodoList(tempTodoList);
        setTempTodoList([]);
        setInputVisible(false);
        handleCreateTodo(task.taskId, tempTodoList)
    };

    const handleCancel = () => {
        setTempTodoList([]);
        setInputVisible(false);
        setInputValue("")
    };

    const toggleCheckbox = (selectedItem) => {
        const updatedList = todoList.map((item, idx) =>
            item === selectedItem ? { ...item, checked: !item.checked } : item
        );
        console.log(updatedList);
        handleUpdateTodo(task.taskId, updatedList);
        setTodoList(updatedList);
    };

    const handleOpenTodoList = () => {
        setInputVisible(true)
        setTempTodoList(todoList)
    }

    const handleCloseModal = () => {
        setInputValue("")
        setInputVisible(false)
        onClose()
    }

    const openDeleteTaskModal = () => {
        setIsDeleteTaskModalOpen(true)
    }

    const handleAcceptDelete = () => {
        handleDeleteTask(task?.taskId)
        handleCloseModal()
        setIsDeleteTaskModalOpen(false)
    }

    const handleDeclineDelete = () => {
        setIsDeleteTaskModalOpen(false)
    }

    console.log(task, "Task");

    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal} position={'right'}>
            <div style={{ flexDirection: 'column', display: 'flex', gap: '5px', height: '100%' }}>
                <span style={{ fontSize: '25px', fontWeight: '400' }}>{task?.taskTitle}</span>
                <span style={{ fontSize: '12px', fontWeight: '400' }}>{task?.taskSubTitle}</span>

                <button className='button-cancel' style={{ marginTop: '15px' }} onClick={openDeleteTaskModal}>{getTranslation('delete')}</button>

                {/* {checkUserPermission(task?.taskMembers, user.id) &&
                    <button className='button-cancel' style={{ marginTop: '15px' }} onClick={() => handleLeaveTask(task?.taskId)}>{getTranslation('leave')}</button>
                } */}
                <button className='button-cancel' style={{ marginTop: '15px' }} onClick={() => handleLeaveTask(task?.taskId)}>{getTranslation('leave')}</button>
                <div style={{ marginTop: '15px', flexDirection: 'column', gap: '10px', display: 'flex', fontWeight: '400', fontSize: '13px' }}>

                    <div className='card' style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>{getTranslation('status')}: {renderStatusChip(taskStatus)}</span>

                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: '10px' }}>
                            {taskStatus === "1" && <span style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => handleChangeStatus(4)}>{getTranslation('start')}</span>}
                            {taskStatus === "2" && <span style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => handleChangeStatus(4)}>{getTranslation('start')}</span>}
                            {taskStatus === "3" && <span style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => handleChangeStatus(4)}>{getTranslation('start')}</span>}
                            {taskStatus === "4" && <span style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => handleChangeStatus(1)}>{getTranslation('complete')}</span>}
                            {taskStatus !== "2" && <span style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => handleChangeStatus(2)}>{getTranslation('cancel')}</span>}
                        </div>
                    </div>

                    <div className='card' style={{ flexDirection: 'column', gap: '10px', display: 'flex', fontWeight: '400', fontSize: '13px', marginTop: '15px' }}>
                        <div style={{ width: '100%', alignItems: 'center', display: 'flex' }}>
                            <button style={{ width: '100%', padding: 5, alignItems: 'center', display: 'flex', justifyContent: 'center' }} onClick={() => setIsEditingDates(!isEditingDates)} ><MdEdit style={{ cursor: 'pointer' }} /></button>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <span>{getTranslation('startDate')}: {formatDate(new Date(startDate))} - {startTime}</span>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            <span>{getTranslation('endDate')}: {formatDate(new Date(endDate))} - {endTime}</span>
                        </div>

                        {isEditingDates && (
                            <div style={{ flexDirection: 'column', gap: '10px', display: 'flex', fontWeight: '400', fontSize: '13px' }}>
                                <label style={{ gap: '10px', flexDirection: 'column', display: 'flex' }}>{getTranslation('startDate')}:
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => handleStartDateChange(e.target.value)}
                                    />
                                    <input
                                        type="time"
                                        value={startTime}
                                        onChange={(e) => handleStartTimeChange(e.target.value)}
                                    />
                                </label>
                                <label style={{ gap: '10px', flexDirection: 'column', display: 'flex' }}>{getTranslation('endDate')}:
                                    <input
                                        type="date"
                                        value={endDate}
                                        min={startDate}
                                        onChange={(e) => handleEndDateChange(e.target.value)}
                                    />
                                    <input
                                        type="time"
                                        value={endTime}
                                        onChange={(e) => handleEndTimeChange(e.target.value)}
                                    />
                                </label>
                            </div>
                        )}
                    </div>

                </div>

                <div className='card' style={{ marginTop: '15px', maxHeight: '250px' }}>
                    <button ref={saveButtonRef} style={{ width: '100%', padding: 5, alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: '10px' }} onClick={handleEditTaskDescription} ><MdEdit style={{ cursor: 'pointer' }} /></button>
                    <div style={{ fontSize: '14px', fontWeight: '400', flexDirection: 'column', gap: '5px', display: 'flex', textAlign: 'justify' }}>
                        {!isEditingDescription ?
                            <span style={{ wordBreak: 'break-word' }}>{taskDescription}</span>
                            :
                            <input
                                type='text'
                                value={taskDescription}
                                onChange={(e) => setTaskDescription(e.target.value)}
                                onBlur={handleBlur}
                            />
                        }
                    </div>
                </div>

                <div className='card' style={{ marginTop: '15px', overflowY: 'auto', maxHeight: '35%' }}>

                    {!inputVisible && (
                        <button style={{ marginTop: '15px', width: '100%' }} onClick={handleOpenTodoList}>
                            {getTranslation('toDoList')}
                        </button>
                    )}

                    {inputVisible && (
                        <>
                            <div style={{ marginTop: '15px', display: 'flex', gap: '10px' }}>
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    placeholder={getTranslation('enterToDo')}
                                />
                                <button onClick={handleAddTodo}>+</button>
                            </div>
                        </>
                    )}

                    {(tempTodoList?.length > 0 || todoList?.length > 0) && inputVisible && (
                        <div style={{ marginTop: '10px' }}>
                            {[...tempTodoList].map((item, index) => (
                                <div key={index} style={{ display: 'flex', gap: '10px', marginBottom: '15px' }}>
                                    <span
                                        style={{
                                            fontSize: '14px',
                                            overflowWrap: 'break-word',
                                            wordBreak: 'break-word',
                                            whiteSpace: 'normal',
                                            width: '100%',
                                            display: 'inline-block',
                                        }}
                                    >
                                        {item.text}
                                    </span>
                                    <MdDeleteOutline
                                        style={{ cursor: 'pointer', marginLeft: 'auto' }}
                                        onClick={() => handleRemoveTempTodo(item)}
                                        size={24}
                                    />
                                </div>
                            ))}
                            <button
                                style={{ marginTop: '10px', width: '100%' }}
                                onClick={handleSaveTodoList}
                            >
                                {getTranslation('save')}
                            </button>
                        </div>
                    )}


                    {inputVisible && (
                        <>
                            <button style={{ marginTop: '15px', width: '100%' }} onClick={handleCancel}>
                                {getTranslation('cancel')}
                            </button>
                        </>
                    )}

                    {!inputVisible && todoList?.length > 0 && (
                        <div style={{ marginTop: '15px' }}>
                            {todoList.map((item, index) => (
                                <div
                                    key={index}
                                    style={{ display: 'flex', alignItems: 'center', marginBottom: '15px', flexDirection: 'row' }}
                                >
                                    <label
                                        style={{
                                            textDecoration: item.checked ? 'line-through' : 'none',
                                            flexDirection: 'row',
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            userSelect: 'none',
                                            gap: '5px',
                                            fontSize: '14px',
                                            flexGrow: 1,
                                            wordBreak: 'break-word',
                                            whiteSpace: 'normal',
                                            maxWidth: '90%',
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={item.checked}
                                            onChange={() => toggleCheckbox(item)}
                                        />
                                        <span
                                            style={{
                                                overflowWrap: 'break-word',
                                                width: '100%',
                                                display: 'inline-block',
                                            }}
                                        >
                                            {item.text}
                                        </span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <MessageBox show={isDeleteTaskModalOpen} title={getTranslation('areYouSure')} message={getTranslation('taskDeleteWarning')} onConfirm={handleAcceptDelete} onClose={handleDeclineDelete} />
        </Modal>
    );
}