import React from "react";
import "./Avatar.css";

export default function Avatar() {
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));
  const localUser = JSON.parse(localStorage.getItem("user"));

  const user = sessionUser ? sessionUser : localUser;

  return (
    <div className="avatar-circle">
      {user.name[0].toUpperCase()}
      {user.lastname[0].toUpperCase()}
    </div>
  );
}
