import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(() => {
    // localStorage'dan temayı oku, yoksa varsayılan olarak dark mode'u kullan
    const savedTheme = localStorage.getItem('darkMode');
    return savedTheme === 'true' || savedTheme === null; // Eğer yoksa default olarak dark mode
  });

  const toggleTheme = () => {
    setDarkMode(prevMode => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode); // Yeni temayı localStorage'a kaydet
      return newMode;
    });
  };

  useEffect(() => {
    // Sayfa her yüklendiğinde temayı ayarla
    document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  return (
    <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
