import React from 'react'
import { useLanguage } from '../LanguageProvider';

export default function LanguageSelect({ isThemeSensitive }) {

    const { language, changeLanguage } = useLanguage();

    const handleLanguageChange = (event) => {
        changeLanguage(event.target.value);
    };

    return (
        <div className='custom-select'>
            <select style={{ width: '100%' }} value={language} onChange={handleLanguageChange}>
                <option value="en">English</option>
                <option value="tr">Türkçe</option>
                <option value="de">Deutsch</option>
                <option value="it">Italiano</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                {/* Add more languages as needed */}
            </select>
        </div>
    )
}
