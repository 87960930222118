import React, { useContext } from "react";
import "./MainPage.css";
import { GeneralContext } from "../../GeneralProvider";
import ApiPage from "../ApiPage/ApiPage";
import DocumentationPage from "../DocumentationPage/DocumentationPage";
import TestAutomationPage from "../TestAutomationPage/TestAutomationPage";
import { useLanguage } from "../../LanguageProvider";
import ProfilePage from "../ProfilePage/ProfilePage";
import TaskManager from "../TaskManager/TaskManager";
import Kronos from "../Kronos/Kronos";

export default function MainPage() {
  const { currentPage, selectedApi, collections } = useContext(GeneralContext);
  console.log("currentPage: ", currentPage);
  const { getTranslation } = useLanguage();

  const renderPage = () => {
    switch (currentPage) {
      case "apis":
        return <ApiPage />;
      case "documentation":
        return <DocumentationPage />;
      case "testautomation":
        return <TestAutomationPage />;
      case "profile":
        return <ProfilePage />;
      case "task-management":
        return <TaskManager />;
      case "kronos":
        return <Kronos />;
      default:
        return null;
    }
  };

  if (
    Object.keys(selectedApi).length > 0 ||
    currentPage === "apis" ||
    currentPage === "profile" ||
    currentPage === "task-management" ||
    currentPage === "kronos"
  ) {
    return <>{renderPage()}</>;
  } else {
    return (
      <div className="main-page">
        <div
          style={{
            width: "100%",
            height: "65%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {collections.length === 0 ? (
            <p>{getTranslation("createNewCollection")}</p>
          ) : (
            <p>{getTranslation("selectApi")}</p>
          )}
        </div>
      </div>
    );
  }
}
