import React, { useEffect, useRef, useState } from "react";
import "./LandingPage.css";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const canvasRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const glitchEffect = () => {
      const width = canvas.width;
      const height = canvas.height;

      for (let i = 0; i < 10; i++) {
        const x = Math.random() * width;
        const lineWidth = Math.random() * 5 + 1;
        const lineHeight = height;

        ctx.fillStyle = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.3)`;
        ctx.fillRect(x, 0, lineWidth, lineHeight);
      }

      setTimeout(() => {
        ctx.clearRect(0, 0, width, height);
      }, 100);
    };

    const animate = () => {
      if (Math.random() > 0.7) {
        glitchEffect();
      }
      requestAnimationFrame(animate);
    };

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    // Sayfa açıldığında body stilini değiştirmek
    useEffect(() => {
      // Sayfaya özel stil ekleme
      document.body.style.backgroundColor = '#0a0a0a'; // body background'ı değiştirme
      document.body.style.color = 'inherit'; // metin rengini normal haline döndürme
      document.body.style.fontFamily = 'inherit'; // fontu geri döndürme
  
      // Temizleme işlemi
      return () => {
        // sayfa değiştikçe eski haline dönmesini sağlama
        document.body.style.backgroundColor = '';
        document.body.style.color = '';
        document.body.style.fontFamily = '';
      };
    }, []);

  return (
    <div className="neo-landing-page no-global-style">
      <canvas ref={canvasRef} className="background-canvas"></canvas>
      <nav className="neo-navbar"  onClick={toggleMenu}>
        <div className="burger-menu">
          <div className={`line ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`line ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`line ${isMenuOpen ? "open" : ""}`}></div>
        </div>
        <ul className={`menu-items ${isMenuOpen ? "open" : ""}`}>
          <li onClick={() => navigate('/test')}>Login</li>
          <li>Home</li>
          <li>Research</li>
          <li>Products</li>
          <li>Developers</li>
          <li>Contact</li>
        </ul>
      </nav>
      <div className="neo-content">
        <div className="neo-text-section">
          <h1 className="glitch" data-text="The Future is HERE">
            The Future is <span>HERE</span>
          </h1>
          <p>
            Unleash the future with cutting-edge AI technology designed to
            revolutionize the way you interact with the digital world.
          </p>
          <button className="neo-cta-button">Know More</button>
        </div>
        <div className="neo-image-section">
          <div className="neo-image-frame">
            <img
              src="https://via.placeholder.com/300"
              alt="AI Robot"
              className="neo-robot-image"
            />
            <div className="neo-ai-label">AI</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
