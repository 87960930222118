import React, { createContext, useEffect, useState } from 'react';
import Services from './services/Services';
import CircularProgress from './components/CircularProgress/CircularProgress';
import { useLanguage } from './LanguageProvider';
import { useToaster } from './ToasterProvider';

const GeneralContext = createContext();

const GeneralProvider = ({ children }) => {

    const { getTranslation } = useLanguage();
    const { toastError, toastSuccess } = useToaster();

    const [selectedApi, setSelectedApi] = useState({});
    const [selectedDocumentation, setSelectedDocumentation] = useState({});
    const [collections, setCollections] = useState([]);
    const [currentPage, setCurrentPage] = useState('collections');
    const [bearerToken, setBearerToken] = useState();
    const [activeTab, setActiveTab] = useState('authorization');
    const [addToCollectionId, setAddToCollectionId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState("");
    const [profilePageSection, setProfilePageSection] = useState("profileInfo");
    const [panels, setPanels] = useState([]);
    const [selectedBoard, setSelectedBoard] = useState({});
    const [selectedPanelId, setSelectedPanelId] = useState("");
    const [addToPanelId, setAddToPanelId] = useState("");

    const sessionToken = sessionStorage.getItem('token');
    const localToken = localStorage.getItem('token');
    const sessionUser = JSON.parse(sessionStorage.getItem('user'));
    const localUser = JSON.parse(localStorage.getItem('user'));

    const isAuthenticated = !!sessionToken || !!localToken;
    const userId = sessionUser ? sessionUser?.id : localUser?.id

    useEffect(() => {
        setToken(localToken ? localToken : sessionToken)
        getAllCollections(localToken ? localToken : sessionToken)
        getAllPanels(localToken ? localToken : sessionToken)
        // eslint-disable-next-line
    }, []);

    //General Functions
    const getUniqueFieldName = (fieldValue, fieldName, existingItems) => {
        const baseFieldValue = fieldValue.replace(/\s\(\d+\)$/, '');

        for (let counter = 0; ; counter++) {
            const newFieldValue = counter === 0 ? baseFieldValue : `${baseFieldValue} (${counter})`;
            if (!existingItems.some(item => item[fieldName].toLowerCase() === newFieldValue.toLowerCase())) {
                return newFieldValue;
            }
        }
    };

    const handleTokenUpdate = (newToken) => {
        if (newToken) {
            setToken(newToken)
            getAllCollections(newToken);
            getAllPanels(newToken);
        }
    };

    const handlePageChange = (page) => {
        setProfilePageSection('profileInfo')
        if (page === "collections" && Object.keys(selectedApi).length > 0) {
            setCurrentPage("apis")
        }
        else {
            setCurrentPage(page);
            if (page === "profile") {
                setSelectedApi({})
                setAddToCollectionId("")
                setAddToPanelId("")
            }
        }
    };

    const handleAddNewApi = (collectionId) => {
        setAddToCollectionId(collectionId)
        setSelectedApi({});
        setCurrentPage('apis');
    };

    const handleAddNewBoard = (panelId) => {
        setAddToPanelId(panelId)
        setSelectedBoard({});
    };

    const handleBearerTokenChange = (token) => {
        setBearerToken(token);
    };

    const handleSelectApi = (api, collectionId) => {
        setAddToCollectionId(collectionId)
        if (currentPage === "collections") {
            setCurrentPage("apis")
        }

        if (api.apiId === selectedApi.apiId) {
            setSelectedApi({});
            if (currentPage === "apis") {
                setCurrentPage("collections")
            }
        }
        else {
            let body = api.body && JSON.stringify(api.body) !== '{}' && typeof api.body !== 'string' ? JSON.stringify(api.body) : api.body;

            if (api.body.length === 0) {
                body = "{}"
            }

            setSelectedApi({
                ...api,
                body,
            });

            Services.GetDocumentationByApiID(token, api.apiId)
                .then((res) => {
                    if (res.data.status) {
                        setSelectedDocumentation(res.data.result[0])
                    }
                    else {
                        toastError(getTranslation('somethingWentWrong'))
                        console.log(res.data)
                    }
                })
                .catch((err) => console.log(err))
        }
    };

    const handleProfileSectionChange = (section) => {
        setProfilePageSection(section)
    }

    // API Functions
    const handleAddApi = (api, response) => {
        // API Create ederken basic-auth ise username password yolla
        if (Object.keys(selectedApi).length === 0) {
            // setIsLoading(true)
            const targetCollection = collections.find(collection => collection.collectionId === addToCollectionId);
            const isDuplicate = targetCollection.apis.some(req => req.apiTitle === api.apiTitle);
            const newName = isDuplicate
                ? getUniqueFieldName(api.apiTitle, 'apiTitle', targetCollection.apis)
                : api.apiTitle;
            Services.CreateAPI(token, addToCollectionId, newName, api.method, api.url, api.auth, api.headers, api.body)
                .then((res) => {
                    if (res.data.status) {
                        const receivedData = res.data.result;

                        const updatedData = {
                            ...receivedData,
                            response: response
                        };

                        const parameters = {
                            body: receivedData.body,
                            url: receivedData.url,
                            title: receivedData.title,
                            method: receivedData.method
                        }

                        Services.CreateDocumentation(token, addToCollectionId, receivedData.apiId, newName, parameters)
                            .then((res) => {
                                if (res.data.status) {
                                    getAllCollections()
                                    setSelectedApi(updatedData)
                                    toastSuccess(getTranslation('successMessage'))
                                }
                                else {
                                    toastError(getTranslation('somethingWentWrong'))
                                    console.log(res.data)
                                }
                            })
                            .catch((err) => console.log(err))
                            .finally(() => setIsLoading(false));
                    }
                    else {
                        toastError(getTranslation('somethingWentWrong'))
                        console.log(res.data)
                    }
                })
                .catch((err) => console.log(err))
        }
    };

    const handleUpdateApi = (apiId, updatedApi) => {
        // API Update ederken basic-auth ise username password yolla

        setIsLoading(true)

        const targetCollection = collections.find(collection => collection.collectionId === addToCollectionId);
        const isDuplicate = targetCollection.apis.some(req => req.apiTitle === updatedApi.apiTitle);
        const newName = isDuplicate
            ? getUniqueFieldName(updatedApi.apiTitle, 'apiTitle', targetCollection.apis)
            : updatedApi.apiTitle;

        const title = selectedApi.apiTitle === updatedApi.apiTitle ? null : newName

        Services.UpdateAPI(token, addToCollectionId, apiId, title, updatedApi.method, updatedApi.url, updatedApi.auth, updatedApi.headers, updatedApi.body)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    const modifiedResult = {
                        ...res.data.result,
                        apiTitle: res.data.result.title ? res.data.result.title : selectedApi.apiTitle,
                        apiId: selectedApi.apiId
                    };
                    delete modifiedResult.title;
                    setSelectedApi(modifiedResult);
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }

            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleSaveAsApi = (api) => {
        setIsLoading(true)
        const targetCollection = collections.find(collection => collection.collectionId === addToCollectionId);
        const isDuplicate = targetCollection.apis.some(req => req.apiTitle === api.apiTitle);
        const newName = isDuplicate
            ? getUniqueFieldName(api.apiTitle, 'apiTitle', targetCollection.apis)
            : api.apiTitle;

        Services.CreateAPI(token, addToCollectionId, newName, api.method, api.url, api.auth, api.headers, api.body)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    setSelectedApi(res.data.result)
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    // Collection Functions
    const handleAddNewCollection = (name) => {
        setIsLoading(true)
        const newName = getUniqueFieldName(name, 'collectionTitle', collections);

        const userID = sessionUser ? sessionUser?.id : localUser?.id

        Services.CreateCollection(token, newName, userID)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const getAllCollections = (inFuncToken) => {
        setIsLoading(true)

        let useToken = inFuncToken ? inFuncToken : token

        Services.GetCollections(useToken)
            .then((res) => {
                if (res.data.status) {
                    setCollections(res.data.result);
                }
                else {
                    setCollections([]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleUpdateCollectionTitle = (collectionId, newName) => {
        setIsLoading(true)
        const uniqueName = getUniqueFieldName(newName, 'collectionTitle', collections);
        Services.UpdateCollection(token, collectionId, uniqueName)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleDeleteCollection = (collectionId) => {
        setIsLoading(true)
        Services.DeleteCollection(token, collectionId)
            .then((res) => {
                if (res.data.status) {
                    getAllCollections()
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));

        if (selectedApi.collectionId === collectionId) {
            setSelectedApi({});
        }

        if (currentPage === 'apis') {
            setCurrentPage('collections');
        }
    };

    //User Functions
    const handleUpdateUser = (userId, name, lastname, email, phone, countryCode) => {
        setIsLoading(true);

        return new Promise((resolve, reject) => {
            Services.UpdateUser(token, userId, name, lastname, email, phone, countryCode)
                .then((res) => {
                    if (res.data.status) {
                        toastSuccess(getTranslation('successMessage'));
                        resolve(true);  // İşlem başarılı olursa resolve ile true döndür
                    } else {
                        toastError(getTranslation('somethingWentWrong'));
                        console.log(res.data);
                        resolve(false); // İşlem başarısız olursa resolve ile false döndür
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err); // Hata durumunda reject ile Promise'ı reddet
                })
                .finally(() => setIsLoading(false));
        });
    };

    const handleUpdateUserPassword = (userId, password) => {
        setIsLoading(true);

        return new Promise((resolve, reject) => {
            Services.UpdateUserPassword(token, userId, password)
                .then((res) => {
                    console.log(res.data.result);
                    if (res.data.status) {
                        toastSuccess(getTranslation('successMessage'));
                        resolve(true);  // İşlem başarılı olursa resolve ile true döndür
                    } else {
                        toastError(getTranslation('somethingWentWrong'));
                        console.log(res.data);
                        resolve(false); // İşlem başarısız olursa resolve ile false döndür
                    }
                })
                .catch((err) => {
                    console.log(err);
                    reject(err); // Hata durumunda reject ile Promise'ı reddet
                })
                .finally(() => setIsLoading(false));
        });
    };

    //Task Management Functions
    const getAllPanels = (inFuncToken, type) => {
        if (type !== "noReload") {
            setIsLoading(true)
        }

        let useToken = inFuncToken ? inFuncToken : token
        Services.GetPanels(useToken)
            .then((res) => {
                if (res.data.status) {
                    setPanels(res.data.result);
                    if (type === "taskAdd" || type === "noReload") {
                        const selectedPanelVar = res.data.result.find(panel => panel.panelId === selectedPanelId)
                        const selectedBoardVar = selectedPanelVar.boards.find(board => board.boardId === selectedBoard.boardId)
                        setSelectedBoard(selectedBoardVar)
                    }
                }
                else {
                    setPanels([]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleAddNewPanel = (name) => {
        setIsLoading(true)
        const newName = getUniqueFieldName(name, 'panelTitle', panels);

        const userID = sessionUser ? sessionUser?.id : localUser?.id

        let members = [userID]

        Services.CreatePanel(token, newName, members)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels()
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleUpdatePanelTitle = (panelId, newName) => {
        setIsLoading(true)
        const uniqueName = getUniqueFieldName(newName, 'panelTitle', panels);
        Services.UpdatePanel(token, panelId, uniqueName)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels()
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleDeletePanel = (panelId) => {

        setIsLoading(true)
        Services.DeletePanel(token, panelId)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels()
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));

        if (selectedPanelId === panelId) {
            setSelectedBoard({});
        }
    };

    const handleAddBoard = (newBoard) => {

        const transformBoardData = (data) => {
            return {
                boardId: data.boardId,
                boardTitle: data.title,
                boardTeamId: data.teamId ? data.teamId : "", // Eğer `teamId` false ise boş string
                boardCreatedBy: data.createdBy,
                boardMembers: data.members,
                tasks: [] // Boş array olarak ayarlanmış
            };
        };

        if (Object.keys(selectedBoard).length === 0) {

            const userID = sessionUser ? sessionUser?.id : localUser?.id

            let members = [userID]
            // setIsLoading(true)
            const targetPanel = panels.find(panel => panel.panelId === addToPanelId);

            const isDuplicate = targetPanel.boards.some(board => board.boardTitle.toLowerCase() === newBoard.title.toLowerCase());

            const newName = isDuplicate
                ? getUniqueFieldName(newBoard.title, 'boardTitle', targetPanel.boards)
                : newBoard.title;
            Services.CreateBoard(token, addToPanelId, newName, members)
                .then((res) => {
                    if (res.data.status) {
                        getAllPanels()
                        setSelectedBoard(transformBoardData(res.data.result))
                        setSelectedPanelId(targetPanel.panelId)
                        toastSuccess(getTranslation('successMessage'))
                    }
                    else {
                        toastError(getTranslation('somethingWentWrong'))
                        console.log(res.data)
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => setIsLoading(false));
        }
    };

    const handleSelectBoard = (board, panelId) => {
        setAddToPanelId(panelId)
        if (board.boardId === selectedBoard?.boardId) {
            setSelectedBoard({});
            setSelectedPanelId("");
        }
        else {
            setSelectedBoard(board);
            setSelectedPanelId(panelId);
        }
    };

    const handleUpdateBoardTitle = (boardId, newTitle) => {
        setIsLoading(true)
        const targetPanel = panels.find(panel => panel.panelId === selectedPanelId);

        const isDuplicate = targetPanel.boards.some(board => board.boardTitle.toLowerCase() === newTitle.toLowerCase());

        const newName = isDuplicate
            ? getUniqueFieldName(newTitle, 'boardTitle', targetPanel.boards)
            : newTitle;
        Services.UpdateBoardTitle(token, boardId, newName)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels()
                    toastSuccess(getTranslation('successMessage'))
                    setSelectedBoard({ ...selectedBoard, boardTitle: newName });
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleDeleteBoard = (boardId) => {
        setIsLoading(true)
        Services.DeleteBoard(token, boardId)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels()
                    toastSuccess(getTranslation('successMessage'))
                    setSelectedBoard({})
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleAddTask = (title, subtitle, description, taskStart, taskEnd) => {
        const userID = sessionUser ? sessionUser?.id : localUser?.id
        let members = [userID]

        Services.CreateTask(token, title, subtitle, description, selectedPanelId, selectedBoard.boardId, members, taskStart, taskEnd)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels(null, "taskAdd")
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleUpdateTaskDates = (taskId, taskStart, taskEnd) => {
        Services.UpdateTaskDates(token, taskId, taskStart, taskEnd)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels(null, "noReload")
                    // toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleCreateTodo = (taskId, todoList) => {
        Services.CreateTodo(token, taskId, todoList)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels(null, "noReload")
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleUpdateTodo = (taskId, todoList) => {
        Services.UpdateTodo(token, taskId, todoList)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels(null, "noReload")
                    // toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleChangeTaskStatus = (taskId, status) => {
        Services.ChangeTaskStatus(token, taskId, status)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels(null, "noReload")
                    // toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleUpdateTaskDescription = (taskId, description) => {
        Services.UpdateTaskDescription(token, taskId, description)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels(null, "noReload")
                    // toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const handleDeleteTask = (taskId) => {
        Services.DeleteTask(token, taskId)
            .then((res) => {
                if (res.data.status) {
                    getAllPanels(null, "noReload")
                    toastSuccess(getTranslation('successMessage'))
                }
                else {
                    toastError(getTranslation('somethingWentWrong'))
                    console.log(res.data)
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    return (
        <GeneralContext.Provider value={{
            currentPage,
            handlePageChange,
            bearerToken,
            handleBearerTokenChange,
            handleAddApi,
            handleSelectApi,
            selectedApi,
            handleAddNewApi,
            handleUpdateApi,
            handleSaveAsApi,
            activeTab,
            setActiveTab,
            handleAddNewCollection,
            collections,
            addToCollectionId,
            handleUpdateCollectionTitle,
            handleDeleteCollection,
            isAuthenticated,
            handleTokenUpdate,
            profilePageSection,
            handleProfileSectionChange,
            handleUpdateUser,
            handleUpdateUserPassword,
            panels,
            selectedBoard,
            selectedPanelId,
            handleAddNewPanel,
            handleUpdatePanelTitle,
            handleSelectBoard,
            handleAddNewBoard,
            handleAddBoard,
            handleDeletePanel,
            handleUpdateBoardTitle,
            handleDeleteBoard,
            handleAddTask,
            userId,
            handleCreateTodo,
            handleUpdateTodo,
            handleUpdateTaskDates,
            handleChangeTaskStatus,
            handleUpdateTaskDescription,
            handleDeleteTask,
            selectedDocumentation
        }}>
            {isLoading ?
                <div style={{ height: '100vh', alignItems: 'center', justifyContent: 'center', display: 'flex', pointerEvents: 'none' }}>
                    <CircularProgress style={{ width: '30px', height: '30px' }} />
                    <div style={{ position: 'absolute', width: '100%', backgroundColor: '#000', opacity: '0.1' }}>{children}</div>
                </div>
                :
                children
            }
        </GeneralContext.Provider>
    );
};

export { GeneralProvider, GeneralContext };