import React from 'react'

export default function NotFound() {
  return (
    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', height: '100vh' }}>
      <p style={{ fontSize: '120px', fontWeight: 'bold', color: '#d8681d' }}>404</p>
      Page Not Found!
    </div>
  )
}
