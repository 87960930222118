import React from "react";
import Modal from "../../../components/Modal/Modal";
import { useLanguage } from "../../../LanguageProvider";

export default function ConfirmDeleteModal({ isOpen, onClose, onConfirm }) {
  const { getTranslation } = useLanguage();

  const handleCloseModal = (decision) => {
    if (decision === "yes") onConfirm();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} position={"middle"}>
      <div
        style={{
          marginTop: "15px",
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        {getTranslation("doYouWantToDelete")}
      </div>

      <div
        style={{
          width: "100%",
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <button onClick={() => handleCloseModal("yes")}>
          {getTranslation("yes")}
        </button>
        <button onClick={() => handleCloseModal("no")}>
          {getTranslation("no")}
        </button>
      </div>
    </Modal>
  );
}
